import { useState, useEffect, useRef, useCallback } from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import Dropdown from '../Dropdown';
import InputField from "../../Components/InputField.js";
import DownloadBtn from '../../Components/DownloadBtn.js';

function ReportTopbar(props){
    const session = props?.session;
    const baseData = props?.baseData;
    const baseModule = props?.baseModule;
    const reporting = session?.reporting;
    const reportingView = props?.reportingView;
    const selectedReport = props?.selectedReport
    const viewType = session?.env?.viewport?.viewType;
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);
    const editingReportStatus = selectedReport?.editable === "always";
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;

    const search = props?.search;
    const rowIndex = props?.rowIndex;
    const colIndex = props?.colIndex;

    // const [search, setSearch] = useState({
    //     searching : props?.viewType !== "mobile" ? true : undefined,
    //     searchValue : selectedReport?.search ?? ''
    // });

    function buttonOptions() {
        let buttonCount = 0;
    
        if (selectedReport?.editable === "yes") {
            buttonCount++;
        }
    
        if (editingReportStatus) {
            buttonCount++;
        }
    
        if (reportType === "inherited" || reportType === "dynamic") {
            buttonCount++;
        }
    
        return buttonCount > 0 ? ` count${buttonCount}` : '';
    }

    return (
        <>
            <div className="topbarWrap g dG">
                {(viewType !== "mobile" || (viewType === "mobile" && reportingView === "list")) && props?.reportCategory &&
                    <div className="bold cC">
                        {props?.reportCategory ? `${props?.reportCategory?.charAt(0)?.toUpperCase() + props?.reportCategory?.slice(1)} Reports` : "Select Category"}
                    </div>
                }
                <div className={`actionBar fR cC dG${
                    search?.data?.searching ? " searching" : ''}${
                        viewType === "mobile" ? " " + reportingView : ''}${
                            reportType ? ` ${reportType}` : " generated"}${
                                props?.remote ? " remote" : ''}${
                                    !selectedReport?.recordID ? " missingReport" : ''}`}
                >
                    {reportType === "inherited" &&
                        <div className="searchContainer f g cC">
                            {(viewType !== "mobile" || (viewType === "mobile" && reportingView === "report")) && reportType === "inherited" &&
                                <div className={`searchBtn f s cL fR lH${search?.data?.searching ? " active" : ''}`}>
                                    <div
                                        className="btn cC"
                                        onClick={() => {
                                            if(viewType === "mobile"){
                                                search?.set((prev) => ({
                                                    ...prev,
                                                    searching: !search?.data?.searching,
                                                }));
                                            }
                                        }}
                                    >
                                        {search?.data?.searching && viewType === "mobile" ?
                                            <SearchOffIcon/>
                                        :
                                            <SearchIcon/>
                                        }
                                    </div>
                                </div>
                            }
                            {(viewType !== "mobile" || (viewType === "mobile" && reportingView === "report" && search?.data?.searching)) &&
                                (reportType === "inherited" || viewType === "mobile") &&
                                <div className="f cL">
                                    <div className={`searchBar g bold cC fR fC`}>
                                        <InputField
                                            search={true}
                                            value={search?.data?.searchValue ?? ''}
                                            placeholder={`Search ${reporting?.data?.appSourceType}s`}
                                            name={"searchValue"}
                                            readOnly={false}
                                            // functionPointer={session?.env?.functions?.updateInput}
                                            functionPointer={props?.handleSearchChange}
                                            setter={search?.set}
                                            autoComplete={"off"}
                                            id={"searchBarInput"}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {reportType === "dynamic" && selectedReport?.subReport?.list &&
                        <div className="f cL">
                            <div
                                className="btnWIcon gC2 bR dG cC p bold lH"
                                onClick={()=>{
                                    const subReport = {...selectedReport?.subReport};
                                    subReport.list = undefined;
                                    subReport.columns = undefined;        
                                    subReport.name = undefined;        
                                    // updateReporting("selectedReport", "subReport", subReport);
                                    session?.set("reporting", "selectedReport.subReport", subReport);
                                }}
                            >
                                <ChevronLeftIcon/>
                                <div className="f cC e">
                                    {selectedReport?.details?.name ?? "Unnamed Report"}
                                </div>
                            </div>
                        </div>
                    }
                    {(viewType !== "mobile"
                        || (viewType === "mobile" && reportingView === "report" && !search?.data?.searching)
                        || reportType === "inherited" && reportingView === "report" && !search?.data?.searching) &&
                        <div className={`reportTitle g bold cC fR fC gC2${props?.dropdowns?.data?.multipleReports ? " mW" : ''}`}>
                            {props?.dropdowns?.data?.multipleReports ?
                                <Dropdown
                                    setShowMenu={(input) => {
                                        props?.dropdowns?.set("multipleReports", {showMenu : input ?? !props?.dropdowns?.data?.multipleReports?.showMenu});
                                    }}
                                    showMenu={props?.dropdowns?.data?.multipleReports?.showMenu}
                                    default={props?.dropdowns?.data?.multipleReports?.reportName ?? props?.dropdowns?.data?.multipleReports?.nameList?.[0]}
                                    prompt={(props?.dropdowns?.data?.multipleReports?.reportName || props?.dropdowns?.data?.multipleReports?.nameList?.[0]) ?
                                        undefined
                                    :
                                        props?.dropdowns?.data?.multipleReports?.prompt
                                    }
                                    generatedList={props?.dropdowns?.data?.multipleReports?.nameList}
                                    onClick={(value, data) => props?.dropdowns?.data?.multipleReports?.onClick(value, data)}
                                    inactive={props?.dropdowns?.data?.multipleReports?.inactive}
                                    reset={props?.resetDropdowns}
                                />
                            :
                                <div className={`g${editingReportStatus ? " dG" : ''}`}>
                                    {editingReportStatus &&
                                        <div className="f cC">
                                            <EditIcon/>
                                        </div>
                                    }
                                    <div className={`f cC${editingReportStatus ? " gC2" : ''}`} onClick={()=>{console.log(session)}}>
                                        {`${selectedReport?.details?.name ?? "Unnamed Report"}${selectedReport?.subReport?.name ?? ''}`}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {(viewType !== "mobile") &&
                        <div
                            className={`btnOptions cR g fR fC f dG e gC3${buttonOptions()}`}
                        >
                            {selectedReport?.editable === "yes" &&
                                <div
                                    className={`btnWIcon bold bR fMP cC ns p${props?.editMode?.data ? " filled" : ''}`}
                                    onClick={()=>{props?.editMode?.set(!props?.editMode?.data)}}
                                >
                                    <div className="f cC lH">
                                        {`${props?.editMode?.data ? "Editing" : "Edit"}`}
                                    </div>
                                    <EditIcon/>
                                </div>
                            }
                            {editingReportStatus && 
                                <div
                                    onClick={()=>{
                                        reporting?.functions?.updateRows(session, selectedReport?.editingRowValues);
                                    }}
                                    className={`btnWIcon bold bR f cC fR${selectedReport?.editingRowValues?.length ? " p" : " inactive"}`}
                                >
                                    <div className="f cC lH">
                                        Save
                                    </div>
                                    <SaveIcon/>
                                </div>
                            }
                            {(reportType === "inherited" || reportType === "dynamic") &&
                                <DownloadBtn
                                    session={session}
                                    setShowMenu={(input) => {
                                        props?.dropdowns?.set("premiumsDownload", {showMenu : input ?? !props?.dropdowns?.data?.premiumsDownload?.showMenu});
                                    }}
                                    showMenu={props?.dropdowns?.data?.premiumsDownload?.showMenu}
                                    inactive={!(viewableReport && selectedReport?.list?.length)}
                                    onClickDownload={() => {
                                        if (viewableReport && selectedReport?.list?.length) {
                                            const onClickHandler = props?.preferredFormat?.[session?.[props?.branch]?.data?.[props?.stem]?.preferredReportFormat]?.onClick;
                                            
                                            if (onClickHandler) {
                                                // If onClick exists, call it
                                                onClickHandler();
                                            } else {
                                                // Otherwise, call the downloadReport function
                                                reporting?.functions?.downloadReport(session, selectedReport, props?.default);
                                            }
                                        }
                                    }}
                                    loadingStatus={props?.preferredFormat?.[session?.[props?.branch]?.data?.[props?.stem]?.preferredReportFormat]?.loadingStatus}
                                    preferredFormat={session?.[props?.branch]?.data?.[props?.stem]?.preferredReportFormat}
                                    formatList={Object.values(props?.preferredFormat || {})}
                                    reset={props?.resetDropdowns}
                                />
                            }
                            <div
                                className="btn more cR p"
                                onClick={()=>{
                                    session?.env?.setOverlay({
                                        name : "reportOptions",
                                        props : {
                                            baseModule : baseModule,
                                            baseData : baseData,
                                            selectedReport : selectedReport,
                                            remotePath : props?.remotePath,
                                        }});
                                }}
                            >
                                <MoreHorizIcon/>
                            </div>
                        </div>
                    }
                    {(viewType === "mobile" && reportingView === "report") &&
                        <div className={
                            `btnOptions cC g fR fC f dG ${(viewType === "mobile"
                            && reportingView === "report"
                            && search?.data?.searching) ?
                            "gC2" : "gC3"}`}
                        >
                            {reportType === "inherited" &&
                                <div
                                    className={`btn circle bold bR fMP cC ns${!viewableReport ? " inactive" : " p"}`}
                                    onClick={viewableReport ? ()=>{ reporting?.functions?.downloadReport(session, selectedReport)} : null}
                                >
                                    <DownloadIcon/>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    )
};

export default ReportTopbar;