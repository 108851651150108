import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash'; // Import lodash for deep comparison

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import ListIcon from '@mui/icons-material/List';
import HeaderBlock from '../Header/HeaderBlock.js';

function HeaderBar({
    session,
    reporting,
    reportType,
    baseModule,
    baseData,
    selectedReport,
    currentAccountID,
    viewType,
    isScrollableX,
    remotePath,
    requestObject,
    viewableReport
}) {
    const dragging = useRef(false);
    const columnRefs = useRef([]);

    const [isResizing, setIsResizing] = useState(false);

    const initializeInputs = () => 
        selectedReport?.columns?.map(column => {
            const subExistingItem = findSubExistingItem(column?.id);
            return subExistingItem?.criterion?.contain ?? '';
        });

    const [inputs, setInputs] = useState(initializeInputs);
    const [isTyping, setIsTyping] = useState(false);
    const inputRefs = useRef(selectedReport?.columns?.map(() => React?.createRef()));

    const handleUpdate = (index, value) => {
        setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[index] = value;
            return newInputs;
        });
        setIsTyping(true);
    };

    function findSubExistingItem(columnId) {
        const subExisting = selectedReport?.criteria?.subExisting ?? [];
        const existingColumn = subExisting.find(item => item?.id === columnId);
        return existingColumn;
    }

    const allColumns = useCallback((returnType) => {
        const rawSubReportColumns = (selectedReport?.subReport?.columns ?? []).filter(column => !column.special);
        const rawReportColumns = (selectedReport?.columns ?? []).filter(column => !column.special);
        
        
        const baseConfig = {
            frozen: true,
            special: true,
        };
    
        const columnDetails = [
            { 
                key: 'rowIndex',
                columnName: 'rowIndex',
                friendlyTerm: 'Row',
                icon: <ListIcon/>,
                width : 50,
            },
            {
                key: 'select',
                columnName: 'selectBubble',
                friendlyTerm: 'Select',
                icon: <HighlightAltIcon/>,
                // onClick: () => reporting?.functions?.toggleAllSelectRows(selectedReport?.sortedListResults, selectedReport?.stem),
                onClick: () => reporting?.functions?.toggleAllSelectRows(selectedReport?.list, selectedReport?.stem),
                width : 50,
            },
            {
                key: 'view',
                columnName: 'view',
                friendlyTerm: 'View',
                icon: <PreviewIcon/>,
                width : 50,
            },
        ];
    
        const specialColumnsConfig = columnDetails.map(({ key, ...rest }) => ({
            ...baseConfig,
            id: `_${key}`,
            key,
            ...rest,
        }));
    
        const specialColumns = currentValidColumns(specialColumnsConfig.filter(({ key }) => selectedReport?.specialColumns?.[key])) || [];

        return returnType === 'count' ?
            specialColumns?.length : 
            [...(specialColumns ?? []), ...(rawSubReportColumns?.length ? rawSubReportColumns : rawReportColumns)];
        }, [selectedReport?.specialColumns,
        reporting?.functions,
        selectedReport?.sortedListResults, 
        selectedReport?.stem,
        selectedReport?.subReport?.columns,
        selectedReport?.columns]
    );

    const columnStructure = useCallback((row) => {
        const baseStructure = selectedReport?.stem === "summary" ?
            {
                gridTemplateColumns: generateGridTemplateColumns(),
            }
            :
            {
                gridTemplateColumns: currentValidColumns(allColumns()).map(column => {
                    const width = column?.title ?? column?.friendlyTerm ?? "*Unnamed Column";
                    return typeof column?.width === 'number' && column?.width > 0 
                        ? `${column?.width}px` 
                        : `${getStringWidth(undefined, width, !column?.special && 82)}px`;
                }).join(' '),
            };
    
        if (!row) {
            baseStructure.zIndex = (allColumns()?.length + 1) || 0;
        }
    
        return baseStructure;
    }, [allColumns, selectedReport?.stem, selectedReport?.recordID, selectedReport?.query]);

    const generateGridTemplateColumns = useCallback(() => {
        const specialColumnsEligible = selectedReport?.subReport?.name || selectedReport?.stem !== "summary";
        const columnCount = specialColumnsEligible && allColumns("count");
        const defaultCols = columnCount > 0 ? `repeat(${columnCount}, 50px) ` : '';

        const additionalCols = `repeat(${
            currentValidColumns(selectedReport?.subReport?.columns || selectedReport?.columns || [])?.length || 0
        }, 1fr)`;
        return defaultCols + additionalCols;
    }, [allColumns,
        selectedReport?.subReport?.name,
        selectedReport?.stem,
        selectedReport?.subReport?.columns,
        selectedReport?.columns,
        selectedReport?.recordID,
        selectedReport?.query
    ]);

    const setupDragHandlers = useCallback((column, index) => {
        let initialX = 0; // This will hold the initial mouse down position
    
        const handleMouseMove = (e) => {
            if (!dragging.current) return; // Ensure dragging is active
        
            const moveX = e.clientX - initialX;
            const newWidth = Math.max(114, selectedReport.columns[index]?.width + moveX);
        
            session?.set(baseModule, `${remotePath ?? ''}selectedReport.columns[${index}].width`, newWidth);
        };
    
        const handleMouseUp = () => {
            dragging.current = false;
            setIsResizing(false);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    
        const handleMouseDown = (e) => {
            initialX = e.clientX; // Capture the starting X position of the mouse on mouse down
            dragging.current = column?.id;
            setIsResizing(true);
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };
    
        return { handleMouseDown };
    }, [session, baseModule]);

    const columnDoubleClickHandler = (e) => {
        const columnsReset = selectedReport.columns.map(column => {
            const width = column?.title ?? column?.friendlyTerm ?? "*Unnamed Column";
            const newWidth = getStringWidth(undefined, width, !column?.special && 82);
    
            return {
                ...column,
                width: newWidth
            };
        });
    
        session?.set(baseModule, `${remotePath ?? ''}selectedReport.columns`, columnsReset);
    };

    const stickyPosition = useCallback((stickyCount, frozen) => {
        if (!frozen) return {};
    
        const columns = allColumns();
        let cumulativeWidth = 0;
        let count = 0;
    
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].frozen) {
                if (count === stickyCount - 1) {
                    break;
                }
    
                if (columnRefs?.[i]?.current) {
                    let currentWidth = columns[i].special ? 50 : columnRefs[i].current.offsetWidth;
                    cumulativeWidth += currentWidth;
                }
    
                count++;
            }
        }

        return { left: `${cumulativeWidth + 2}px`, zIndex: columns.length || 0 };
    }, [allColumns]);

    const currentValidColumns = useCallback((columns) => {
        const specialColumnsEligible = selectedReport?.subReport?.name || selectedReport?.stem !== "summary";
        const currentStem = selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem;
        const hasFiles = reporting?.data?.reportDataHandler?.[currentStem]?.folderName;

        return Object.entries(columns)
            .filter(([, column]) => 
                !column?.placeholder &&
                (specialColumnsEligible || !column?.special) &&
                (column?.key !== "select" || hasFiles)
            )
            .map(([, column]) => column);
    }, [reporting?.data?.reportDataHandler, selectedReport?.referenceStem, selectedReport?.stem, selectedReport?.subReport?.name]);

    const getStringWidth = (() => {
        const cache = new Map();
        const measureContainerId = "columnMeasure";
    
        const getMeasureContainer = () => {
            const measureContainer = document.getElementById(measureContainerId);
            // if (!measureContainer) {
            //     console.error(`Element with id ${measureContainerId} not found.`);
            // }
            return measureContainer;
        };
    
        return (existingWidth, text, spacing = 0) => {
            const cacheKey = text + spacing;
            if (cache.has(cacheKey)) {
                return cache.get(cacheKey);
            }
    
            const measureContainer = getMeasureContainer();
            if (!measureContainer) {
                return existingWidth ?? 200; // Return a default width if measure container is not found
            }
    
            measureContainer.textContent = text;
            const textWidth = measureContainer.scrollWidth;
    
            const finalWidth = Math.min(existingWidth ?? textWidth, 200) + spacing;
            cache.set(cacheKey, finalWidth);
    
            // Clear the measure container content
            measureContainer.textContent = '';
    
            return finalWidth;
        };
    })();

    const setupStickyColumnsExclusion = useCallback(() => {
        const columns = allColumns();
        const ineligibleColumns = new Set();
        let foundCustom = false;
        
        columns.forEach((column, index) => {
            if (column?.custom !== undefined) {
                foundCustom = true;
                ineligibleColumns.add(column?.id);
                if (index > 0) {
                    ineligibleColumns.add(columns?.[index - 1]?.id);
                }
            }
        });
    
        if (!foundCustom && columns.length > 0) {
            ineligibleColumns.add(columns[columns.length - 1].id);
        }
    
        return ineligibleColumns;
    }, [allColumns]);

    const printColumnHeaders = useCallback(() => {
        let stickyCount = 0;
        let columnHeaders = [];
        let columnCount = currentValidColumns(allColumns())?.length || 0;

        if(selectedReport?.columns?.length > 0){
            const sorting = [...(selectedReport?.sorting || [])];
            const shouldProceed = selectedReport?.stem === "summary"
            ? selectedReport?.columns.filter(column => !column.hasOwnProperty('custom')).length > 1
            : true;

            currentValidColumns(allColumns())?.forEach((column, index) => {
                const frozen = column?.frozen;
                const columnPointer = column?.fieldBy ?? column?.columnName;
                const currentIndex = sorting?.findIndex(obj => obj.columnName === columnPointer);
                const ascending = sorting?.[currentIndex]?.type === "asc";
                const descending = sorting?.[currentIndex]?.type === "desc";

                columnRefs[index] = columnRefs[index] || React.createRef();
                if (frozen){
                    stickyCount++;
                    columnCount--;
                }

                columnHeaders.push(
                    <div
                        ref={columnRefs[index]}
                        key={index}
                        className={`columnBlock cC g pR fR${column?.onClick ?
                            " p" : ''}${frozen && isScrollableX && !column?.special ?
                                " frozen" : ''}${column?.altColor ?
                                    " alt" : ''}${column?.special ?
                                        " special frozen" : ''}${
                                            frozen && stickyCount === 1 ? " first" : ''}${dragging?.current ? dragging?.current === column?.id ?
                                                " dragging ns" : " ns" : ''
                                            }`
                        }
                        style={{
                            ...stickyPosition(stickyCount, frozen),
                        }}
                        onClick={column?.onClick ? column?.onClick : null}
                        onMouseOver={e => {
                            if (!dragging.current) {
                                e.currentTarget.classList.add("hovering");
                            }else{
                                e.currentTarget.classList.remove("hovering")
                            }
                        }}
                        onMouseLeave={e => e.currentTarget.classList.remove("hovering")}
                    >
                        {isScrollableX && !column?.special && !(selectedReport?.stem === "summary" && !selectedReport?.subReport?.name) &&
                            <div
                                className="f cC p setFrozen"
                                onClick={()=>{
                                    session?.set(baseModule, `${remotePath ?? ''}selectedReport.columns.${index - allColumns("count")}.frozen`, !frozen)
                                }}
                            >
                                <AcUnitIcon/>
                            </div>
                        }
                        {selectedReport?.stem === "summary" || !viewableReport || selectedReport?.editable === "always" || column?.special ?
                            <div
                                className={`${column?.friendlyTerm ? "bold " : "italics "}f cC${
                                    !column?.special ? " gCW gC2" : " cC lH fR g"}`}
                                title={column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                            >
                                {column?.special ? column?.icon : (viewType === "mobile" && column?.mobileFriendlyTerm) || column?.friendlyTerm || "*Unnamed Column"}
                            </div>
                        :
                            <HeaderBlock
                                session={session}
                                column={column}
                                index={index}
                                baseData={baseData}
                                baseModule={baseModule}
                                allColumns={allColumns}
                                selectedReport={selectedReport}
                                remotePath={remotePath}
                                dragging={dragging?.current}
                                columnRef={columnRefs.current?.[index]}
                                inputRef={inputRefs.current?.[index]}
                                handleUpdate={handleUpdate}
                                inputs={{data : inputs, set : setInputs}}
                                findSubExistingItem={findSubExistingItem}
                            />
                        }
                        {!column?.special && !column?.summarizeBy && shouldProceed &&
                            <>
                                <div className={`sort f g p gC3`}>
                                    {['asc', 'desc'].map((sortType) => {
                                        const isActive = sortType === 'asc' ? ascending : descending;
                                        const IconComponent = sortType === 'asc' ? ArrowDropUpIcon : ArrowDropDownIcon;

                                        return (
                                            <div
                                                key={sortType}
                                                className={`icon${isActive ? " active" : ''}`}
                                                onClick={() => {
                                                    const existingIndex = sorting.findIndex(s => s.columnName === columnPointer);

                                                    let newSorting;
                                                    if (existingIndex !== -1) {
                                                        if (sorting[existingIndex].type === sortType) {
                                                            newSorting = sorting.filter((_, index) => index !== existingIndex);
                                                        } else {
                                                            newSorting = [...sorting];
                                                            newSorting[existingIndex] = { ...newSorting?.[existingIndex], type: sortType };
                                                        }
                                                    } else {
                                                        newSorting = [...sorting, { type: sortType, columnName: columnPointer}];
                                                    }

                                                    session?.set(baseModule, `${remotePath ?? ''}selectedReport.sorting`, newSorting);

                                                    if (reportType === "generated") {
                                                        reporting?.functions?.requestResultBlock(
                                                            { newSorting },
                                                            requestObject,
                                                            currentAccountID,
                                                        );
                                                    }
                                                }}
                                            >
                                                <IconComponent />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    className={`columnDrag g cC${dragging?.current ? " dragging" : ''}`}
                                    key={index}
                                    onMouseDown={(e) => setupDragHandlers(column, index - allColumns("count")).handleMouseDown(e)}
                                    onDoubleClick={columnDoubleClickHandler}
                                >
                                    <div className="bar">
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                );
            });
        }

        return (
            columnHeaders?.length > 0 ?
                columnHeaders
            :
                <div className="f cC">
                    No columns selected
                </div>
        );
    }, [allColumns, selectedReport?.sorting, currentValidColumns, getStringWidth, isScrollableX, reportType, reporting?.functions, requestObject, selectedReport?.columns, selectedReport?.stem, selectedReport?.subReport?.columns, selectedReport?.subReport?.name, session, stickyPosition, viewType, viewableReport]);

    useEffect(() => {
        if (!isTyping) return;

        const tempCurrentSubExisting = [...(selectedReport?.criteria?.subExisting ?? [])];

        inputs.forEach((value, index) => {
            if (value !== undefined) {
                const column = selectedReport.columns[index];
                const subExistingColumn = findSubExistingItem(column.id);
                const colIndex = tempCurrentSubExisting.findIndex(item => item?.id === column.id);

                if (colIndex === -1 && value) {  // No existing column and value is not empty
                    const subQuerySegment = {
                        attr: column?.columnName,
                        criterion: { contain: value },
                        groupID: 1,
                        groupOperator: "AND",
                        inlineOperator: "OR",
                        id: column.id,
                    };
                    tempCurrentSubExisting.push(subQuerySegment);
                } else if (colIndex !== -1 && value === '') {  // Existing column and value is empty
                    tempCurrentSubExisting.splice(colIndex, 1);  // Remove the item at the found index
                } else {
                    if (subExistingColumn?.criterion?.contain) {
                        tempCurrentSubExisting[colIndex].criterion.contain = value;
                    }
                }
            }
        });

        session?.set(baseModule, `${remotePath ?? ''}selectedReport.criteria.subExisting`, tempCurrentSubExisting);

        setIsTyping(false);
    }, [isTyping, inputs]);

    useEffect(() => {
        setInputs(initializeInputs);
    }, [selectedReport?.recordID]);

    return {
        data: {
            dragging,
            isResizing,
        },
        functions: {
            getStringWidth,
            printColumnHeaders,
            currentValidColumns,
            stickyPosition,
            columnStructure,
            setupStickyColumnsExclusion,
            allColumns,
        }
    };
}

export default HeaderBar;
