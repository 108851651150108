import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';  // Import lodash for deep comparison

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { CircularProgress } from '@mui/material';
import ReportSheetEditableCell from './ReportSheetEditableCell.js';
import ReportActionBar from './ReportActionBar.js';
import HeaderBar from './Header/HeaderBar.js';
import EditMode from './EditMode.js';
import ResultsRenderHandler from './SummaryReport/ResultsRenderHandler.js';
import PrintingFunctions from './Functions/PrintingFunctions.js';

function ReportSheet(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const baseModule = props?.baseModule ?? `reporting${currentAccountID ? "." + currentAccountID : ''}`;
    const baseData = props?.baseData ?? session?.[props?.baseModule]?.data ?? reporting?.data;

    const [floater, setFloater] = useState({
        top: false,
        bottom: false,
    });

    const reportTable = useRef(null);
    const columnContainerRef = useRef(null);
    const rowRefs = useRef({});
    const lastScrollTop = useRef(0);
    
    const [stickyColumns, setStickyColumns] = useState({});
    const [columnWidth, setColumnWidth] = useState(null);
    const [isScrollableX, setIsScrollableX] = useState(false);
    const [stickyDisplay, setStickyDisplay] = useState();
    const [renderedList, setRenderedList] = useState([]);

    const cachedFormattedDates = new Map();
    const printingFunctions = PrintingFunctions({session, cachedFormattedDates})?.functions;
    // const search = props?.search;

    const importedList = props?.importedList;

    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;

    const selectedReport = props?.remoteReport ?? props?.session?.[baseModule]?.data?.selectedReport;
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);

    const hasIterableData = session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem]?.[0];
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const rowErrors = session?.edit?.data?.rowErrors;
    const selectableRows = !selectedReport?.edit

    const editingCell = session?.edit?.data?.editHandler?.position;
    const validateEdit = session?.edit?.functions?.validateEdit;
    const displayErrors = session?.edit?.data?.displayErrors;
    const filterErrors = session?.edit?.data?.filterErrors;

    const currentSorting = [...(selectedReport?.sorting || [])];
    const selectedDropdown = props?.selectedDropdown?.data;
    const setSelectedDropdown = props?.selectedDropdown?.set;
    const sortedData = { list: undefined };
    const [tempReportReference, setTempReportReference] = useState(selectedReport);

    const columnHeaders = HeaderBar({
        session,
        reporting,
        reportType,
        baseModule,
        baseData,
        selectedReport,
        currentAccountID,
        viewType,
        isScrollableX,
        remotePath : props?.remotePath,
        requestObject : props?.requestObject,
        columnWidth,
        viewableReport,
    });

    const [eligibleStickyColumns, setEligibleStickyColumns] = useState(columnHeaders?.functions?.setupStickyColumnsExclusion());
    const [infiniteScrollMax, setInfiniteScrollMax] = useState(100);
    // const pagination = props?.pagination?.data;
    // const setPagination = props?.pagination?.set;

    const isMounted = useRef(false);
    const unmounted = selectedReport?.loaded && !isMounted.current;

    const [pendingChanges, setPendingChanges] = useState([]);

    const handleEditModeInputChange = (recordID, columnName, value) => {
        setPendingChanges(prevChanges => {
            const newChanges = [...prevChanges];
            const existingChangeIndex = newChanges.findIndex(change => change.recordID === recordID && change.columnName === columnName);

            if (existingChangeIndex > -1) {
                newChanges[existingChangeIndex].value = value;
            } else {
                newChanges.push({ recordID, columnName, value });
            }

            return newChanges;
        });
    };

    function calculateColumnWidth(){
        const measureColumnWidth = () => {
            if (columnContainerRef.current) {
                const gridColumnGap = parseFloat(getComputedStyle(columnContainerRef.current)?.gridColumnGap) || 0;

                const gridContainerWidth = columnContainerRef.current.clientWidth;
                const numActualColumns = selectedReport?.columns?.length || 0;
                const numSpecialColumns = columnHeaders?.functions?.allColumns("count")

                const totalSpecialColumnsWidth = numSpecialColumns * 50;
                const availableWidthForRegularColumns = gridContainerWidth - totalSpecialColumnsWidth - (numActualColumns * 16);
                const columnWidthInPixels = (availableWidthForRegularColumns + gridColumnGap * (numActualColumns - 1)) / numActualColumns;

                setColumnWidth(columnWidthInPixels + 16);
            }
        };

        measureColumnWidth();
      
        window.addEventListener('resize', measureColumnWidth);
      
        return () => {
            window.removeEventListener('resize', measureColumnWidth);
        };
    }

    function printListResults(list, call){
        // console.log(call);
        let rowCount = 0;
        let filterCount = 0;
        let stickyCount = 0;
        let columnCount = columnHeaders?.functions?.allColumns()?.length || 0;

        let results = [];
        // const searchData = search?.data?.searchValue?.toLowerCase()?.split(' ');
        const searchData = selectedReport?.search?.toLowerCase()?.split(' ');
        const memoize = (func) => {
            const cache = new Map();
            return (...args) => {
              const key = JSON.stringify(args);
              if (cache.has(key)) {
                return cache.get(key);
              }
              const result = func(...args);
              cache.set(key, result);
              return result;
            };
        };

        const memoizedRenderFormattedColumnValue = memoize(printingFunctions?.renderFormattedColumnValue);

        if (!selectedReport?.list && !list) {
            // session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.renderedList`, []);
            setRenderedList([]);
            return null;
        }

        const sortList = (a, b, currentSorting) => {
            for (const sortCriteria of currentSorting) {
                const { columnName, type } = sortCriteria;
                const valueA = a[columnName];
                const valueB = b[columnName];

                const parsedValueA = isNaN(valueA) ? valueA : parseFloat(valueA);
                const parsedValueB = isNaN(valueB) ? valueB : parseFloat(valueB);

                if (parsedValueA === null && parsedValueB !== null) {
                    return type === 'asc' ? 1 : -1;
                }

                if (parsedValueA !== null && parsedValueB === null) {
                    return type === 'asc' ? -1 : 1;
                }

                if (typeof parsedValueA === 'undefined' && typeof parsedValueB === 'undefined') {
                    continue;
                }

                if (typeof parsedValueA === 'undefined' || parsedValueA === null) {
                    return type === 'asc' ? 1 : -1;
                }

                if (typeof parsedValueB === 'undefined' || parsedValueB === null) {
                    return type === 'asc' ? -1 : 1;
                }

                if (!isNaN(parsedValueA) && !isNaN(parsedValueB)) {
                    if (parsedValueA !== parsedValueB) {
                        return type === 'asc' ? parsedValueA - parsedValueB : parsedValueB - parsedValueA;
                    }
                } else {
                    if (typeof parsedValueA === 'string' && typeof parsedValueB === 'string') {
                        if (parsedValueA !== parsedValueB) {
                            return type === 'asc' ? parsedValueA.localeCompare(parsedValueB) : parsedValueB.localeCompare(parsedValueA);
                        }
                    } else {
                        const stringValueA = String(parsedValueA);
                        const stringValueB = String(parsedValueB);
                        if (stringValueA !== stringValueB) {
                            return type === 'asc' ? stringValueA.localeCompare(stringValueB) : stringValueB.localeCompare(stringValueA);
                        }
                    }
                }
            }

            return 0;
        };

        const primaryList = list ?? selectedReport?.derivedList ?? selectedReport?.subReport?.list ?? selectedReport?.list;
        sortedData.list = primaryList?.map((item, index) => ({...item, _key: index})).sort((a, b) => sortList(a, b, currentSorting));

        if (reportType === "inherited" && selectedReport?.search) {
            const searchTerms = selectedReport?.search?.toLowerCase()?.split(' ');
            const findRowInEditingRowValues = (rowObj) => {
                return (selectedReport?.editingRowValues ?? []).some(row => row?.[reporting?.data?.selectAllVar] === rowObj?.[reporting?.data?.selectAllVar]);
            };

            sortedData.list = sortedData.list.filter((rowObj) => {
                return searchTerms.every((term) => {
                    return columnHeaders?.functions?.allColumns()?.some((column) => {

                        const attr = column?.columnName;
                        const columnValueLower = rowObj?.[attr]?.toString()?.toLowerCase();
                        const isInEditingRowValues = findRowInEditingRowValues(rowObj);
                        // const isSelectedRow = selectionState?.selectedRows?.some(row => row.recordID === rowObj?.[baseData?.selectAllVar]);
                        const isSelectedRow = selectedReport?.selectionState?.selectedRows?.some(row => row.recordID === rowObj?.[reporting?.data?.selectAllVar]);

                        if (columnValueLower?.includes(' ')) {
                            const attributeWords = columnValueLower?.split(' ');
                            return attributeWords?.some((word) => word?.includes(term));
                        } else if (isSelectedRow || isInEditingRowValues) {
                            return true;
                        } else {
                            return memoizedRenderFormattedColumnValue(columnValueLower, attributeData?.[attr]?.returnType, "stringOnly")?.includes(term);
                        }
                    });
                });
            });
        }

        const totalResults = sortedData?.list?.length;

        if (selectedReport?.stem === "summary" && !selectedReport?.subReport?.list) {
            const resultsHandler = ResultsRenderHandler({
                session,
                sortedData,
                attributeData,
                cachedFormattedDates,
                renderFormattedColumnValue : printingFunctions?.renderFormattedColumnValue,
                // searchData,
                baseModule,
                allColumns : columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns()),
                rowRefs : rowRefs.current,
                remotePath : props?.remotePath,
                selectedReport,
            })?.functions;
        
            const currFunction = selectedReport?.details?.displayType === "dynamic" ? "generateBlocks" : "generateRows";
        
            results = resultsHandler?.[currFunction]?.();
        }else{
            let rowStickyStructure = [];
            const startIndex = (selectedReport?.details?.scrollType === "pagination" && reportType !== "generated")
            ? (props?.dropdowns?.data?.pagination?.index - 1) * (props?.dropdowns?.data?.pagination?.rowMax ?? 0)
            : 0;
        
            const endIndex = (selectedReport?.details?.scrollType === "infinite")
            ? Math.min(sortedData?.list?.length, infiniteScrollMax)
            : sortedData?.list?.length;

            const printRow = (index, rowObj, path) => {
                // const showMenu = props?.editCellDropdownHandler?.data?.showMenu;
                const showMenu = props?.dropdowns?.data?.editMode?.showMenu;
                const priorityRow = typeof showMenu === 'string' && showMenu.split('-')[0] === String(index);
                
                const commonProps = {
                    key: index,
                    className: `row f fR g${selectableRows && (baseModule === "edit" || selectedReport?.editable === "always") ? " editing" : " p"}${priorityRow ? " priority" : ''}`,
                    style : columnHeaders?.functions?.columnStructure(true),
                    onClick: () => {
                        if (baseModule !== "edit") {
                            reporting?.data?.reportDataHandler?.[selectedReport?.referenceStem ?? selectedReport?.stem]?.onClick?.(session, rowObj, selectedReport, importedList);
                        }
                    }
                };

                const rowCells = columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns()).map((column, colIndex) => {
                    const frozen = column?.frozen;
                    if(colIndex === 0){
                        stickyCount = 0;
                        columnCount = columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns()).length;
                    }

                    if (frozen){
                        stickyCount++;
                        columnCount--;
                    }

                    let columnValue = rowObj?.[column?.columnName];

                    if(baseModule === "edit"){
                        if ((column?.columnName === 'dob' || column?.columnName === 'benef_dob')){
                            //columnValue = session?.edit?.functions?.excelSerialDateToDate(columnValue).toLocaleDateString();                                                          
                        }else{
                            if (columnValue == null) {
                                columnValue = '-';
                            }
                        }
                    }

                    const isCellError = rowErrors?.some(error => error.row === rowObj?._key && error.column === colIndex);

                    const editingCellIndex = editingCell?.[index.toString()];

                    let isEditing = false;
                    if ((editingCell !== undefined || null) && (editingCellIndex !== undefined || null) && (Object?.keys(editingCell) !== undefined || null) && (Object?.keys(editingCellIndex) !== undefined || null)){
                        if(Object?.keys(editingCell)?.includes(index?.toString()) && Object?.keys(editingCellIndex)?.includes(colIndex?.toString())){
                            let rowPos = Object?.keys(editingCell)?.indexOf(index?.toString());
                            let colPos = Object?.keys(editingCellIndex)?.indexOf(colIndex?.toString());
                            isEditing = Object?.keys(editingCell)?.[rowPos] == index && Object?.keys(editingCellIndex)?.[colPos] == colIndex;
                        }     
                    }else if((selectedReport?.editable && column?.editable)){
                        isEditing = true;
                    }

                    let validEdit = null;
                    if (isCellError){        
                        validEdit = validateEdit(columnValue, column?.columnName);    
                    }

                    !rowStickyStructure?.[colIndex] && (rowStickyStructure[colIndex] = columnHeaders?.functions?.stickyPosition(stickyCount, frozen));

                    if(column?.special){
                        return (
                            <div
                                key={index + "-" + colIndex}
                                className={`cell frozen special g cC zT f g fC fR${attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${frozen && isScrollableX ? " frozen" : ''}${frozen && stickyCount === 1 ? " first" : ''}`}
                                style={rowStickyStructure?.[colIndex]}
                                onClick={(e)=>{
                                    if(props?.editMode){
                                        e?.stopPropagation();
                                    }
                                }}
                            >
                                {column?.columnName === "rowIndex" &&
                                    index + 1
                                }
                                {column?.columnName === "selectBubble" &&
                                    (
                                        selectedReport?.selectionState?.selectedRows?.some(row => row.recordID === rowObj?.[reporting?.data?.selectAllVar]) ?
                                            <CheckBoxIcon
                                                className="p"
                                                onClick={(e)=>{
                                                    reporting?.functions?.selectRowHandler(
                                                        index,
                                                        rowObj?.[reporting?.data?.selectAllVar],
                                                        // sortedData?.list,
                                                        selectedReport?.list,
                                                        selectedReport?.stem
                                                    )(e)
                                                }}
                                            />
                                        :
                                            <CheckBoxOutlineBlankIcon
                                                className="p"
                                                onClick={(e)=>{
                                                    reporting?.functions?.selectRowHandler(
                                                    index,
                                                    rowObj?.[reporting?.data?.selectAllVar],
                                                    // sortedData?.list,
                                                    selectedReport?.list,
                                                    selectedReport?.stem
                                                    )(e);
                                                }}
                                            />
                                    )
                                }
                                {column?.columnName === "view" &&
                                    <Link
                                        to={path}
                                        className="cellLink"
                                    >
                                        <VisibilityIcon
                                            className="p"
                                        />
                                    </Link>
                                }
                            </div>
                        )
                    }

                    return (
                        (baseModule === "edit") ?
                            <div
                                id={"scroll-"+index+ "-" + colIndex}
                                key={`${index}-${colIndex}Invalid`}
                                className={`f ${
                                    attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${
                                        frozen && isScrollableX ? " frozen" : ''}`
                                }
                                style={columnHeaders?.functions?.stickyPosition(stickyCount, frozen)}
                            >
                                <ReportSheetEditableCell
                                    session={session}
                                    index={index}
                                    rowKey={rowObj?._key}
                                    colIndex={colIndex}
                                    validEdit={validEdit}
                                    isCellError={isCellError}
                                    isEditing={isEditing}
                                    columnValue={isEditing ? session?.edit?.data?.editHandler?.position?.[index]?.[colIndex] : columnValue}
                                    column={column}
                                    importedList={props?.importedList}
                                    memoizedRenderFormattedColumnValue = {memoizedRenderFormattedColumnValue}
                                    attributeData = {attributeData}
                                    // searchData = {searchData}
                                    frozen = {frozen}
                                    stickyPosition = {columnHeaders?.functions?.stickyPosition}
                                    isScrollableX = {isScrollableX}
                                    editableReport = {baseModule !== "edit"}
                                    reset = {props?.reset}
                                    selectedDropdown={{ data : selectedDropdown, set : setSelectedDropdown}}
                                    editCellDropdownHandler={props?.editCellDropdownHandler}
                                />
                            </div>
                        :
                            <div
                                key={index + "-" + colIndex}
                                className={`cell f cC zT g fC fR${attributeData?.[column?.columnName]?.altColor ? " alt" : ''}${frozen && isScrollableX ? " frozen" : ''}${frozen && stickyCount === 1 ? " first" : ''}`}
                                style={rowStickyStructure?.[colIndex]}
                            >
                                <div
                                    className={`gCW${["dropdown", "generatedList"].includes(attributeData?.[column?.columnName]?.formType) && (props?.editMode || selectedReport?.editable === "always") ? " alt" : ''}`}
                                    title={typeof columnValue === 'number' ? columnValue.toLocaleString() : columnValue}
                                >
                                    {selectedReport?.editable === "always" && column?.editable ?
                                        <EditMode
                                            session={session}
                                            baseData={baseData}
                                            selectedReport={selectedReport}
                                            rowIndex={index}
                                            colIndex={colIndex}
                                            columnValue={columnValue}
                                            columnData={column}
                                            importedList={props?.importedList}
                                            memoizedRenderFormattedColumnValue = {memoizedRenderFormattedColumnValue}
                                            attributeData={attributeData}
                                            searchData={searchData}
                                            frozen={frozen}
                                            stickyPosition={columnHeaders?.functions?.stickyPosition}
                                            isScrollableX={isScrollableX}
                                            editableReport={baseModule !== "edit"}
                                            resetDropdowns={props?.resetDropdowns}
                                            selectedDropdown={{ data : selectedDropdown, set : setSelectedDropdown}}
                                            // editCellDropdownHandler={props?.editCellDropdownHandler}
                                            rowObj={rowObj}
                                            handleEditModeInputChange={handleEditModeInputChange}
                                            dropdowns={props?.dropdowns}
                                        />
                                    :
                                        <span>
                                            {memoizedRenderFormattedColumnValue(
                                                columnValue,
                                                attributeData?.[column?.columnName]?.returnType,
                                                searchData,
                                                props?.importedList && isCellError ? 'excel': undefined
                                            )}
                                        </span> 
                                    }
                                </div>
                            </div>
                    );
                });

                results.push(
                    path && selectedReport?.editable !== "always" ? (
                        <Link
                            key={index + "Path"}
                            to={path}
                            {...commonProps}
                        >
                            {rowCells}
                        </Link>
                    ) : (
                        <div
                            key={index + "Default"}
                            {...commonProps}
                        >
                            {rowCells}
                        </div>
                    )
                );
                rowCount++;
                filterCount++;
            }

            for (let index = startIndex; index < endIndex; index++) {
                const rowObj = sortedData?.list[index];
                if(filterErrors){
                    if(
                        (
                            selectedReport?.details?.scrollType === "infinite" && 
                            filterCount > infiniteScrollMax
                        ) ||
                        (
                            selectedReport?.details?.scrollType === "pagination" &&
                            index >= ( reportType === "generated" ? true : (props?.dropdowns?.data?.pagination.index - 1) * props?.dropdowns?.data?.pagination?.rowMax ?? 0 ) &&
                            filterCount >= props?.dropdowns?.data?.pagination?.rowMax
                        )
                    ){
                        break;
                    }
                } else {
                    if(
                        (
                            selectedReport?.details?.scrollType === "infinite" && rowCount > infiniteScrollMax
                        ) ||
                        (
                            selectedReport?.details?.scrollType === "pagination" &&
                            index >= ( reportType === "generated" ? true : (props?.dropdowns?.data?.pagination?.index - 1) * props?.dropdowns?.data?.pagination?.rowMax ?? 0 ) &&
                            rowCount >= props?.dropdowns?.data?.pagination?.rowMax
                        )
                    ){
                        break;
                    }
                }

                const path = reporting?.functions?.formulatePath(rowObj, selectedReport);
                if(selectedReport?.details?.scrollType === "infinite"){
                    if(selectedReport === importedList && displayErrors && filterErrors && filterCount < infiniteScrollMax){
                        if(rowErrors?.some(error => error.row === rowCount)){
                            printRow(index, rowObj, path);
                        } else {
                            rowCount++;
                        }
                    } else if (rowCount < infiniteScrollMax){
                        printRow(index, rowObj, path);
                    }
                }

                if(selectedReport?.details?.scrollType === "pagination" &&
                    index >= ( reportType === "generated" ? 0 : (props?.dropdowns?.data?.pagination?.index - 1) * props?.dropdowns?.data?.pagination?.rowMax ?? 0 ) &&
                    rowCount < props?.dropdowns?.data?.pagination?.rowMax
                ){
                    printRow(index, rowObj, path);
                }
            };
        }

        const paths = [
            // `${props?.remotePath ?? ''}selectedReport.renderedList`,
            `${props?.remotePath ?? ''}selectedReport.totalFound`,
            `${props?.remotePath ?? ''}selectedReport.filteredList`,
        ];
        const values = [
            // results,
            totalResults,
            sortedData.list
        ];
        
        if (selectedReport?.loaded !== true) {
            paths.push(`${props?.remotePath ?? ''}selectedReport.loaded`);
            values.push(true);
        }

        session?.set(baseModule, paths, values);
        setRenderedList(results);
    }

    const handleWindowScroll = () => {
        const scrollPosition = window.scrollY;
        const fullScrollableLength = document.documentElement.scrollHeight;
        const innerScrollableLength = fullScrollableLength - window.innerHeight;
        const isScrollable = fullScrollableLength > window.innerHeight;

        if (scrollPosition < (innerScrollableLength - 52) && isScrollable) {
            setFloater({
                top: false,
                bottom: true,
            });
        } else {
            setFloater({
                top: false,
                bottom: false,
            });
        }
    };

    const handleScroll = (e) => {
        const scrollContainer = e.target;
        const currentScrollTop = scrollContainer?.scrollTop;
        const scrollDirection = currentScrollTop > lastScrollTop.current ? "down" : "up";
        lastScrollTop.current = currentScrollTop;

        const containerRect = scrollContainer.getBoundingClientRect();
        let lastVisibleKey = null;

        const stickyColumnsHandler = columnHeaders?.functions?.allColumns().reduce((acc, column) => {
            acc[column.id] = {};
            return acc;
        }, {});

        if (currentScrollTop < 84 &&
            (selectedReport?.stem === "summary" && !selectedReport?.subReport?.name) &&
            Object.values(stickyColumns ?? {}).some(handler => Object.keys(handler).length > 0) && 
            scrollDirection === "up" || 
            (currentScrollTop === 0 && Object.keys(stickyColumns).length > 0)) {
            setStickyColumns({});
            return;
        }

        const isNearBottom = selectedReport?.details?.scrollType === "infinite" && 
            (scrollContainer.scrollHeight - currentScrollTop - scrollContainer.clientHeight < 25);
        if (isNearBottom && infiniteScrollMax < selectedReport?.list?.length) {
            setInfiniteScrollMax(prevMax => prevMax + 100);
        }

        Object.keys(rowRefs.current).forEach(key => {
            const refEntry = rowRefs.current[key];
            const element = refEntry.ref.current;
            if (element) {
                const rect = element.getBoundingClientRect();
                const relativeTop = rect.top - containerRect.top;
                if (relativeTop < 72) {
                    lastVisibleKey = key;
                    const columnId = refEntry.data.id;
                    if (!eligibleStickyColumns.has(columnId)) {
                        stickyColumnsHandler[columnId] = {
                            ...refEntry.data,
                            value: refEntry.data.rangeInfo.range
                        };
                    }
                }
            }
        });

        if (lastVisibleKey && !reporting?.functions?.isEqualWithoutAttributes(stickyColumnsHandler, stickyColumns, [])) {
            if (currentScrollTop === 0) {
                setStickyColumns({});
            } else {
                setStickyColumns(stickyColumnsHandler);
            }
        }
    };
    
    const maintainScrollStatus = () =>{
        const checkScrollable = () => {
            const el = reportTable.current;
            if (el) {
                const scrollable = el.scrollWidth > el.clientWidth;
                setIsScrollableX(scrollable);
            }
        };

        checkScrollable();
        window.addEventListener('resize', checkScrollable);

        return () => window.removeEventListener('resize', checkScrollable);
    }

    const emptyResultsHandler = () => {
        const searchValue= selectedReport?.search || '';
        const subExisting = selectedReport?.criteria?.subExisting;
        const attributeData = session?.case?.data?.attributeData;
    
        const getFriendlyTerm = (attr) => attributeData?.[attr]?.friendlyTerm || attr;
        const createBoldText = (text) => <span className="bold">{text}</span>;
    
        const generateDescriptions = () => {
            if (!subExisting || subExisting.length === 0) return null;
    
            const descriptions = subExisting.map((sub, index) => {
                const columnName = getFriendlyTerm(sub.attr);
                const criterionValue = sub.criterion[Object.keys(sub.criterion)[0]];
                return (
                    <span key={index}>
                        {columnName} containing "{createBoldText(criterionValue)}"
                    </span>
                );
            });
    
            if (descriptions.length === 1) {
                return descriptions[0];
            }
    
            const lastDescription = descriptions.pop();
            return descriptions.length > 0
                ? [...descriptions.reduce((acc, curr) => [...acc, curr, ', '], []).slice(0, -1), ' and ', lastDescription]
                : lastDescription;
        };
    
        const descriptions = generateDescriptions();
    
        if (searchValue && descriptions) {
            return (
                <>
                    No rows found for the search term "{createBoldText(searchValue)}"
                    <br />and<br />
                    {descriptions}.
                </>
            );
        }
    
        if (searchValue) {
            return (
                <>
                    No rows found for the search term "{createBoldText(searchValue)}."
                </>
            );
        }
    
        if (descriptions) {
            return (
                <>
                    No rows found for {descriptions}.
                </>
            );
        }
    
        return "No available results found.";
    };    

    useEffect(() => {
        if (unmounted) { return; }
        // if(selectedReport?.list && !session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing){
        if(selectedReport?.list && !session?.env?.isTyping && !session?.env?.isDragging && !columnHeaders?.data?.isResizing){
            printListResults(undefined, 1);
            calculateColumnWidth();
            if(viewType === "mobile"){
                window.scrollTo(0, 0);
            }else{
                if(!props?.editMode && baseModule !== "edit"){
                    reportTable.current.scrollTop = 0;
                    reportTable.current.scrollLeft = 0;
                }
            }
            setInfiniteScrollMax(100);
        }
        handleWindowScroll();
    }, [
        props?.dropdowns?.data?.pagination?.index,
        props?.dropdowns?.data?.pagination?.rowMax,
        isScrollableX,
        props?.editMode,
        selectedReport?.details?.displayType,
        selectedReport?.subReport?.list
    ]);

    session?.useListen(() => {
        if (unmounted) { return; }

        let query = reporting?.functions?.buildQuery(selectedReport?.criteria?.subExisting);
        const items = selectedReport?.list;
        const filteredResults = reporting?.functions?.filterItemsWithQuery(query, items);
        session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.derivedList`, filteredResults, "HERE") //:
        printListResults(filteredResults ?? items, 6546);

    }, [selectedReport?.criteria?.subExisting], true);

    useEffect(() => {
        if (unmounted) { return; }

        if(selectedReport?.list && !session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing){
            printListResults(undefined, 2);
            calculateColumnWidth();
        }
    // }, [props?.editCellDropdownHandler?.data]);
    }, [props?.dropdowns?.data?.editMode]);

    useEffect(() => {
        if (unmounted) { return; }

        if(!session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing){
            printListResults(undefined, 3);
        }
        calculateColumnWidth();
    }, [selectedReport?.selectionState?.selectedRows]);

    useEffect(() => {
        if (unmounted) { return; }

        if(!session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing){
            printListResults(undefined, 3);
        }
        calculateColumnWidth();
    }, [columnHeaders?.data?.isResizing]);

    useEffect(() => {
        if (unmounted) { return; }

        if(selectedReport?.query){
            if(reportType !== "generated" && !selectedReport?.list?.length && selectedReport?.recordID && props?.session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]){
                const items = session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem];
                const filteredResults = reporting?.functions?.filterItemsWithQuery(selectedReport?.query, items);
                session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.list`, filteredResults, "HERE") //:
            }

            printListResults(undefined, 6);
        }
    }, [session?.[reporting?.data?.dataPointer]?.data]);

    const columnsDependency = JSON.stringify(selectedReport?.columns);

    useEffect(() => {
        if (unmounted) { return; }
        if (!session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing) {
          printListResults(undefined, 4);
        }

        maintainScrollStatus();
    }, [
        infiniteScrollMax,
        selectedReport?.criteria?.groupBy,
        session?.env?.isDragging,
        selectedReport?.specialColumns?.rowIndex,
        selectedReport?.specialColumns?.select,
        selectedReport?.specialColumns?.view,
        columnsDependency,
    ]);

    useEffect(()=>{
        if (unmounted) { return; }

        let stickyColumnsDisplay = undefined;
        const hasStickyData = columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns()).some(column => {
            const handler = stickyColumns?.[column?.id];
            return handler && Object.keys(handler)?.length > 0;
        });

        if(selectedReport?.stem === "summary"){
            const stickyStyle = selectedReport?.columns?.length ? columnHeaders?.functions?.columnStructure() : { zIndex: (columnHeaders?.functions?.allColumns())?.length || 0 + 1 };
            stickyStyle.visibility = hasStickyData ? 'visible' : 'hidden';
            stickyStyle.display = hasStickyData ? 'grid' : 'contents';
    
            stickyColumnsDisplay = (
                <div
                    key="stickyColumnsDisplay"
                    className={`stickyColumnsDisplay`}
                    style={stickyStyle}
                >
                    {columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns()).map((column, index) => {
                        const handler = stickyColumns?.[column?.id];
                        return handler && Object.keys(handler)?.length > 0 ? (
                            <div key={column?.id} style={{ gridColumn: index + 1 }} className="dP">
                                <div
                                    className="groupByItem f cC p g bold bR"
                                    onClick={()=>{
                                        const subReport = {...selectedReport?.subReport};
                                        subReport.list = handler?.["rawList" ?? "list"];
                                        subReport.columns = reporting?.data?.defaultColumns?.[selectedReport?.referenceStem]?.detailedArray;

                                        const existingColumnNames = new Set(subReport.columns.map(col => col.columnName));
                                        const nonCustomColumns = [];
                                        const customColumns = [];

                                        selectedReport?.columns.forEach(column => {
                                            // Check if the column is custom
                                            const isCustom = column?.custom;
                                            const columnNameKey = isCustom ? column?.fieldBy : column?.columnName; // For custom columns, use fieldBy as the identifier
                                    
                                            if ((column?.summarizeBy !== "Count") && !existingColumnNames.has(columnNameKey)) {
                                                const newColumn = {
                                                    id: subReport.columns.length.toString(), // Incremental ID based on the current length
                                                    columnName: column?.columnName ?? column?.fieldBy, // Use fieldBy for custom columns
                                                    friendlyTerm: attributeData?.[column?.columnName ?? column?.fieldBy]?.friendlyTerm || column?.friendlyTerm,
                                                    frozen: column?.frozen,
                                                    editable: true // Assuming all added columns are editable
                                                };
                                    
                                                if (isCustom) {
                                                    customColumns.push(newColumn);
                                                } else {
                                                    nonCustomColumns.push(newColumn);
                                                }
                                            }
                                        });
                                    
                                        // Add non-custom columns to the front and custom columns to the end
                                        subReport.columns = [...nonCustomColumns, ...subReport.columns, ...customColumns];

                                        session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.subReport`, subReport);
                                    }}
                                >
                                    <span className="f gCW s e">
                                        {handler?.value}
                                    </span>
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            );
        }

        setStickyDisplay(stickyColumnsDisplay);
    }, [stickyColumns]);

    useEffect(() => {
        if (unmounted) { return; }

        rowRefs.current = {};

        if (!session?.env?.isTyping && !session?.env?.isDragging && selectedReport?.list?.length && !columnHeaders?.data?.isResizing) {
            printListResults(undefined, 8);
        }

        reportTable.current.scrollTop = 0;
    }, [
        // baseData?.sorting,
        selectedReport?.sorting,
    ]);

    useEffect(() => {
        if (unmounted) { return; }

        if(props?.dropdowns?.data?.pagination){
            props?.dropdowns?.set?.("pagination", {"index" : 1})
            // setPagination((prev) => ({
            //     ...prev,
            //     index: 1,
            // }));
        }
    }, [props?.dropdowns?.data?.pagination?.rowMax]);

    useEffect(() => {
        if (pendingChanges.length === 0) return;

        const deepEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
        
            if (keys1.length !== keys2.length) {
                return false;
            }
        
            for (const key of keys1) {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        
            return true;
        };
        
        const timer = setTimeout(() => {
            const currentData = selectedReport?.editingRowValues || [];
            const originalList = selectedReport?.list || [];
            const updatedArray = currentData.map(row => ({ ...row }));

            pendingChanges.forEach(change => {
                const originalObj = originalList.find(item => item?.[reporting?.data?.selectAllVar] === change?.[reporting?.data?.selectAllVar]);
                const currentObj = updatedArray.find(row => row?.[reporting?.data?.selectAllVar] === change?.[reporting?.data?.selectAllVar]);
                if (originalObj) {
                    if (currentObj) {
                        if (originalObj?.[change.columnName] !== change?.value) {
                            updatedArray[updatedArray.indexOf(currentObj)] = {
                                ...currentObj,
                                [change.columnName]: change?.value
                            };
                        } else if (!deepEqual(currentObj, originalObj)) {
                            updatedArray.splice(updatedArray.indexOf(currentObj), 1);
                        } else {
                            console.log(3333333333);
                            console.log(currentObj, originalObj);
                            console.log("No difference detected! 1");
                        }
                    } else {
                        if(originalObj?.[change.columnName] !== change?.value){
                            console.log(originalObj?.[change.columnName], change?.value);

                            const newRecord = {
                                ...originalObj,
                                [change.columnName]: change?.value
                            };
                            updatedArray.push(newRecord);
                        }else{
                            console.log("No difference detected! 2");
                        }
                    }
                } else {
                    console.error(`Original object not found for recordID: ${change?.[reporting?.data?.selectAllVar]}`);
                }
            });

            session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.editingRowValues`, updatedArray);

            setPendingChanges([]);
        }, 700);

        return () => clearTimeout(timer);
    }, [pendingChanges]);

    useEffect(() => {
        if (unmounted) return;
        if (selectedReport?.editable === "always" && !session?.env?.isTyping) {
            printListResults(undefined, 47);
        }
    }, [selectedReport?.editingRowValues]);

    useEffect(() => {
        if (unmounted) return;
    }, [selectedReport?.recordID]);

    useEffect(() => {
        if (unmounted) return;
        const initializeColumnWidths = (columns) => {
            const anyWidthMissing = columns.some(column => !column.width);
          
            if (!anyWidthMissing) {
              return columns;
            }
          
            const containerWidth = reportTable.current ? reportTable.current.offsetWidth : 500; // Fallback width
            const proportionateWidth = Math.floor(containerWidth / columns.length);
          
            const finalColumns = columns.map(column => {
              if (!column.width) {
                return {
                  ...column,
                  width: proportionateWidth
                };
              }
          
              const width = parseInt(columnHeaders?.functions?.getStringWidth(column.width, column.title ?? column.friendlyTerm ?? "*Unnamed Column", !column.special && 82), 10);
              return {
                ...column,
                width: width
              };
            });

            session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.columns`, finalColumns);

            return finalColumns;
        };

        const columns = columnHeaders?.functions?.currentValidColumns(columnHeaders?.functions?.allColumns());
        // const finalColumns = initializeColumnWidths(columns);
        initializeColumnWidths(columns);

        rowRefs.current = {};
    
        const paths = [];
        const values = [];
    
        if (selectedReport?.query) {
            if (reportType !== "generated") {
                const items = session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem];
                const filteredResults = reporting?.functions?.filterItemsWithQuery(selectedReport?.query, items);
                paths.push(`${props?.remotePath ?? ''}selectedReport.list`);
                values.push(filteredResults);
    
                printListResults(filteredResults, 11);
            }
        }

        if (selectedReport?.lastRecordID !== selectedReport?.recordID && !props?.remotePath) {
            // if(reporting?.data?.sorting?.length > 0){
            if(selectedReport?.sorting?.length > 0){
                // paths.push(`${props?.remotePath ?? ''}sorting`);
                paths.push(`${props?.remotePath ?? ''}selectedReport.sorting`);
                values.push([]);
            }

            setTempReportReference(selectedReport);

            if(selectedReport?.lastPageIndex && selectedReport?.lastPageIndex !== 1){
                paths.push(`${props?.remotePath ?? ''}selectedReport.lastPageIndex`);
                values.push(1);

                if (props?.dropdowns?.data?.pagination) {
                    props?.dropdowns?.set("pagination", {"index" : 1});
                    // setPagination((prev) => ({
                    //     ...prev,
                    //     index: 1,
                    // }));
                }
            }
        }

        paths.push(`${props?.remotePath ?? ''}selectedReport.lastRecordID`);
        values.push(selectedReport?.recordID);

        session?.set(baseModule, paths, values);

        if (reportTable?.current) {
            reportTable.current.scrollLeft = 0;
        }
    }, [
        selectedReport?.recordID,
        selectedReport?.query,
        session?.[reporting?.data?.dataPointer]?.data?.[selectedReport?.referenceBranch ?? selectedReport?.branch]?.[selectedReport?.referenceStem ?? selectedReport?.stem]
    ]);

    useEffect(() => {
        if (unmounted) { return; }

        if(props?.dropdowns?.data?.pagination){
            props?.dropdowns?.set("pagination", {"index" : 1});
        }

        if(!session?.env?.isTyping && selectedReport?.loaded){
            printListResults(undefined, 5);
        }
    }, [
        // session?.env?.isTyping,
        // search?.data?.searchValue,
        selectedReport?.criteria?.existing,
        selectedReport?.search,
        session?.edit?.data
    ]);

    useEffect(() => {
        if (unmounted) { return; }

        maintainScrollStatus();
        window.addEventListener('scroll', handleWindowScroll);
    }, [, viewport?.width]);

    useEffect(() => {
        isMounted.current = true;
        if(selectedReport?.query && selectedReport?.loaded){
            printListResults(undefined, 999);
        }
    }, []);

    return (
        <div
            key="report"
            className={`report${
                !props?.remoteReport ? " b" : ''}${
                viewType !== "mobile" && !props?.remoteReport ? " s e" : ''} g zTPC${
                    selectedReport?.ID && !selectedReport?.loaded ? " gathering" : ''}${
                        selectedReport?.details?.displayType === "dynamic" && selectedReport?.stem === "summary" && viewableReport && !selectedReport?.subReport?.list ? " blockType" : ''}${
                            selectedReport?.stem === "summary" && !selectedReport?.subReport?.list ? " summary" : ''}${
                                columnHeaders?.data?.isResizing ? " mouseDrag" : ''}`}
        >
            <div
                key="reportTable"
                ref={reportTable}
                onScroll={(e)=>{handleScroll(e)}}
                className={`list g${(!viewableReport ||
                    renderedList?.length === 0 ||
                    // selectedReport?.renderedList?.length === 0 ||
                    !selectedReport?.loaded) ? " inactive" : ''}`}
            >
                <div id="columnMeasure">
                </div>
                <div
                    key="columnHeader"
                    id="columnHeader"
                    className={`columnHeader fR g${
                        (selectedReport?.subReport?.columns?.length ?? selectedReport?.columns?.length) > 5 && !props?.remoteReport ? " expanded" : ''}${
                            !viewableReport ? " loading" : ''}`}
                    style={selectedReport?.columns?.length ? columnHeaders?.functions?.columnStructure() : {zIndex : (columnHeaders?.functions?.allColumns())?.length || 0 + 1}}
                    ref={columnContainerRef}
                >
                    {columnHeaders?.functions?.printColumnHeaders()}
                    <div
                        className="dragLinePlot g"
                        style={selectedReport?.columns?.length ?
                            columnHeaders?.functions?.columnStructure()
                        :
                            {zIndex : (columnHeaders?.functions?.allColumns())?.length || 0 + 1}}
                    >
                    </div>
                </div>
                {stickyDisplay}
                {!viewableReport ?
                    <div key="unviewableReport" className={`loading empty f cC g`}>
                        Select a report or complete the following to get a new report started
                        <div className="checkList thin g">
                            <div className="g">
                                <div className="f cL">
                                    Filters
                                </div>
                                {selectedReport?.query?.length > 0 ?
                                    <CheckCircleIcon/>
                                :
                                    <HighlightOffIcon/>
                                }
                            </div>
                            <div className="g">
                                <div className="f cL">
                                    Columns
                                </div>
                                {selectedReport?.columns?.length > 0 ?
                                    <CheckCircleIcon/>
                                :
                                    <HighlightOffIcon/>
                                }
                            </div>
                        </div>
                    </div>
                :
                    <React.Fragment key="viewableReport">
                        {renderedList?.length > 0 && selectedReport?.list?.length > 0 ?
                            renderedList
                        :
                            (hasIterableData && selectedReport?.loaded || selectedReport?.totalFound === 0) ?
                                <div className="unavailableList loading f cC"
                                    // onClick={()=>{console.log(hasIterableData, selectedReport?.loaded, selectedReport?.totalFound);}}
                                    onClick={()=>{console.log(renderedList, selectedReport?.list);}}
                                >
                                    <span>
                                        {emptyResultsHandler()}
                                    </span>
                                </div>
                            :
                                <div className="loading f cC g">
                                    <CircularProgress color="inherit"/>
                                </div>
                        }
                    </React.Fragment>
                }
            </div>
            {/* {selectedReport?.editable === "always" &&
                <div className="confirmationBar g fR">
                    <div className="prompt f g cC gC2 fR">
                        <div className="f cC bold">
                            {"Editing Mode"}
                        </div>
                    </div>
                    <div className="save f g cR gC3 fR">
                        <div
                            onClick={()=>{
                                reporting?.functions?.updateRows(session, selectedReport?.editingRowValues);
                            }}
                            className={`btnWIcon bold bR f dP dP cC ns p fC fR${selectedReport?.editingRowValues?.length ? '' : " inactive"}`}
                        >
                            <div className="f cC lH">
                                Save
                            </div>
                            <SaveIcon/>
                        </div>
                    </div>
                </div>
            } */}
            {/* {baseModule !== "edit" && !props?.remoteReport && */}
                <ReportActionBar
                    session={session}
                    baseModule={baseModule}
                    remotePath={props?.remotePath}
                    floater={{data : floater, set : setFloater}}
                    importedList={props?.importedList}
                    // pagination={{data : pagination, set : setPagination}}
                    resetDropdowns={props?.resetDropdowns}
                    requestObject={props?.requestObject}
                    selectedReport={selectedReport}
                    dropdowns={props?.dropdowns}
                />
            {/* } */}
        </div>
    )
}

export default ReportSheet;