import { React, useState, useEffect, useRef } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import WcIcon from '@mui/icons-material/Wc';

function Fetchhandler(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const casePath = session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem];

    const currentCase = props?.currentCase;
    const updateLoadingHandler = props?.updateLoadingHandler;
    const loadingHandler = props?.loadingHandler;
    const branch = props?.branch;
    const stem = props?.stem;

    function fetchEligibility(setter){
        if (!loadingHandler?.[setter] || !currentCase?.data || !session?.case?.data?.[props?.branch]?.[props?.stem]) {
            return false;
        }

        return true;
    }

    const updateReadStatus = async () => {
        const { fetchPath, params } = (() => {
            switch (stem) {
                case "origination":
                    return {
                        fetchPath: "updateReadStatus",
                        params: {
                            "type": "policy",
                            "relatedID": currentCase?.data?.relatedPolicyID,
                            "unread": false
                        }
                    };
                case "servicing":
                    return {
                        fetchPath: "servicing/updateReadStatus",
                        params: {
                            "type": "policy",
                            "policyID": currentCase?.data?.recordID,
                            "unread": false
                        }
                    };
                case "bids":
                    return {
                        fetchPath: "marketplace/updateReadStatus",
                        params: {
                            "type":"task",
                            "taskID": currentCase?.data?.recordID,
                            "unread": false
                        }
                    };
                case "tertiary":
                    return {
                        fetchPath: "tertiary/updateReadStatus",
                        params: {
                            "type": "bid",
                            "recordBidID": currentCase?.data?.recordID,
                            "unread": false,
                        }
                    };
            }
        })();

        try {
            const response = await session?.env?.functions?.buildFetchRequest(fetchPath, params);
            const resData = await response.json();

            if (resData.status === 200) {
                return [];
            }

        } catch (error) {
            console.error(error);
            updateLoadingHandler("timeline", false, 3);
            return false;  // Explicitly return false in case of error
        }
    };

    const loadTimeline = async () => {
    
        // Safeguard: Ensure loadTimeline is only executed once
        if (!loadingHandler?.timeline || !currentCase?.data) {
            return null;  // Explicitly return null or some default value to indicate early exit
        }
    
        updateLoadingHandler("timeline", "pending");
    
        if (currentCase?.data?.timeline) {
            updateLoadingHandler("timeline", false, 1);
            return currentCase?.data?.timeline; // Return the existing data
        }

        const pointerKey = (() => {
            switch (stem) {
                case "origination":
                    return "relatedPolicyID";
                case "servicing":
                    return "recordID";
                case "tertiary":
                    return "recordBidID";
                case "bids":
                    return "taskID";
                case "leads":
                    return "leadID";
            }
        })();

        const fetchPath = (() => {
            switch (stem) {
                case "origination":
                    return "pullSinglePolicyTimeline";
                case "servicing":
                    return "servicing/getPolicyTimeline";
                case "bids":
                    return "marketplace/getBidActivities";
                case "tertiary":
                    return "tertiary/getTimeline";
                case "leads":
                    return "pullLeadActivity";
            }
        })();

        const pointerValue = (() => {
            switch(stem) {
                case "origination":
                    return currentCase?.data?.relatedPolicyID
                case "servicing":
                    return currentCase?.data?.recordID
                case "tertiary":
                    return currentCase?.data?.recordID
                case "bids":
                    return currentCase?.data?.recordID
                case "leads":
                    return currentCase?.data?.recordID
            }
        })();

        // const pointerValue = stem === "origination"
        //     ? currentCase?.data?.relatedPolicyID
        //     : currentCase?.data?.recordID;

        let timelineData = {
            openRequestCounter: 0,
            requestCounter: 0,
            list: [],
            timelineType : "default",
            showDetails : false,
        };

        let vocData = { firstVOCDate: undefined, lastVOC: undefined };

        // const tempCurrentCase = currentCase?.data;
        // tempCurrentCase.timeline = false;

        const params = { [pointerKey]: pointerValue };
        if(props?.stem === "leads") params.accountID = session?.user?.data?.accountID;

        try {
            const response = await session?.env?.functions?.buildFetchRequest(fetchPath, params);
            const resData = await response.json();

            if (resData.status === 200) {
                if (stem === "origination") {
                    if (resData?.policyTimelineData && typeof resData.policyTimelineData === 'object') {
                        Object.values(resData.policyTimelineData).forEach(item => {
                            timelineData.list.push(item);
                
                            if (item.type === "Request") {
                                if (item.checkStatus === "Open") {
                                    timelineData.openRequestCounter++;
                                }
                                timelineData.requestCounter++;
                            }
                        });
                    }
                }
    
                if (stem === "servicing") {
                    resData?.timeline?.forEach(item => {
                        if (item.carrierTask === "VOC") {
                            if (!vocData.firstVOCDate) vocData.firstVOCDate = item?.dateCreated;
                            if (!vocData.lastVOC) vocData.lastVOC = { ...item };
                        }
    
                        timelineData.list.push(item);
                    });
    
                }
    
                if (stem === "tertiary" || stem === "bids") {
                    resData?.timeline?.forEach(item => {
                        timelineData.list.push(item);
                    });
                }

                if (stem === "leads") {
                    resData?.data?.forEach(item => {
                        timelineData.list.push(item);
                    });
                }

                updateReadStatus();
                return {timeline : timelineData, vocData : vocData}; // Properly return the timeline data
            } else {
                throw new Error('Failed to load timeline data');
            }
        } catch (error) {
            console.error(error);
            updateLoadingHandler("timeline", false, 3);
            return false;  // Explicitly return false in case of error
        }
    };

    const loadServicingPremiums = async () => {
        if (!fetchEligibility("premiums")) return;

        const fetchPath = stem === "tertiary" ? "tertiary/getBidPS" : stem === "bids" ? "marketplace/getBidsBPs" : "servicing/getPremiumSchedule";

        if (!currentCase?.data?.premiumsData) {
            const today = new Date();
    
            const paramVals = {
                "recordID": currentCase?.data?.recordID,
                // "startDate": `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`,
            };
    
            const keys = {
                "servicing" : "projectedPremiums",
                "tertiary" : "premiumSchedules",
                "bids" : "premiumSchedules",                
            }

            try {
                const response = await session?.env?.functions?.buildFetchRequest(fetchPath, paramVals);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const resData = await response.json();
    
                if (resData.status === 200) {
                    if(stem === "servicing"){
                        const premiumsData = {
                            nextPremium: resData?.projectedPremiums?.[0]?.premiumSchedule?.[0],
                            premiumSchedule: resData?.projectedPremiums,
                            selectedPremiumKey : 0,
                        };
        
                        return premiumsData;
                    }

                    if(stem === "tertiary" || stem === "bids"){
                        const premiumsData = {
                            nextPremium: resData?.premiumSchedules?.[0],
                            premiumSchedule: resData?.premiumSchedules,
                            selectedPremiumKey : 0,
                        };
        
                        return premiumsData;
                    }
                } else {
                    console.error("Failed to fetch premiums data:", resData);
                    return false;
                }
            } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error("Error fetching premium schedule:", error);
                return false;
            }
        } else {
            return false;
        }
    };

    const loadShareLink = async () => {
        if (!fetchEligibility("shareLink")) return;
    
        updateLoadingHandler("shareLink", "pending");
    
        if (casePath?.[currentCase?.index]?.generatedShareLink) {
            updateLoadingHandler("shareLink", false, 4);
            return casePath?.[currentCase?.index]?.generatedShareLink; // Return the existing shareLink
        }

        function locateSharLinkBase(){
            const shareLinkPath = {
                "tertiary" : "tertShareLink",
                "bids" : "bidShareLink",
                "servicing" : "lmaShareLink",
                "origination" : "shareLink",
            }

            const shareLinkKey = {
                "tertiary" : "policyNumber",
                "bids" : "clarinetRecordID",
                "servicing" : "policyNumber",
                "origination" : "policyNumber",
            }

            return `${session?.user?.data?.accountData?.[currentCase?.data?.relatedAccountID]?.[shareLinkPath?.[stem]]}/${currentCase?.data?.[shareLinkKey?.[stem]]}`;
        }

        const locatedShareLink = locateSharLinkBase()
        const params = {
            "pullType": props?.stem === "bids" ? "secondary" : props?.stem,
            "link": currentCase?.data?.shareLink ?? locatedShareLink,
        };
    
        try {
            const response = await session?.env?.functions?.buildFetchRequest("getShareLinks", params);
            const resData = await response.json();
    
            if (resData.status === 200) {
                // updateLoadingHandler("shareLink", false, 5);
                return resData?.shareLink; // Return the shareLink if successful
            } else {
                updateLoadingHandler("shareLink", false, 6);
                return false;
            }
        } catch (error) {
            updateLoadingHandler("shareLink", false, 7);
            return false;
        }
    };

    const loadLifeExpectancies = async () => {
        if (!fetchEligibility("les")) return null; // Explicitly return null if not eligible
    
        updateLoadingHandler("les", "pending");
    
        try {
            const response = await session?.env?.functions?.buildFetchRequest(stem === "tertiary" ? "tertiary/getBidLEs" : "marketplace/getBidsBLEs",
                { recordID: currentCase?.data?.recordID });
            const resData = await response.json();
    
            if (resData.status === 200) {
                // updateLoadingHandler("lifeExpectancies", false, 8);
                return resData?.leList;  // Return activities directly if successful
            } else if (resData.status === 500) {
                return null;  // Explicitly return null if no activities found
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error("Error loading life expectancies:", error);
            updateLoadingHandler("les", false, 9);
            return null;  // Explicitly return null in case of error
        }
    };

    const loadBids = async () => {
        if (!fetchEligibility("bids")) return null; // Explicitly return null if not eligible
    
        updateLoadingHandler("bids", "pending");
    
        try {
            const response = await session?.env?.functions?.buildFetchRequest("pullSinglePolicyBidsData", { relatedPolicyID: currentCase?.data?.relatedPolicyID });
            const resData = await response.json();
    
            if (resData.status === 200) {
                let bidData = {};
                Object.entries(resData.bidData).forEach(([key, currentBid], index) => {
                    const bid = {
                        bidDate: session?.env?.functions?.reformatDate(currentBid?.bidDate),
                        bidAmount: currentBid?.bidAmount,
                        bidNotes: currentBid?.bidNotes,
                        bidRDB: currentBid?.bidRDB,
                        x: index
                    };
                    bidData[index] = bid;
                });

                return bidData;
            } else if (resData.status === 500) {
                return null;  // Explicitly return null if no activities found
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error("Error loading bid data:", error);
            updateLoadingHandler("bids", false, 9);
            return null;  // Explicitly return null in case of error
        }
    };

    const loadMessages = async () => {
        if (!fetchEligibility("messages")) return null;

        updateLoadingHandler("messages", "pending");

        const fetchPath = (() => {
            switch(stem) {
                case "origination":
                    return "pullTranscript"
                case "servicing":
                    return "servicing/getPolicyMessages"
                case "bids":
                    return "marketplace/getBidMessages"
            }
        })();

        const params = (() => {
            switch(stem) {
                case "origination":
                    return {
                        "relatedPolicyID" : currentCase?.data?.relatedPolicyID,
                        "accountID" : currentCase?.data?.relatedAccountID,
                    }
                case "servicing":
                    return {
                        "policyID" : currentCase?.data?.recordID,
                    }
                case "bids":
                    return {
                        "taskID" : currentCase?.data?.recordID,
                    }
            }
        })();

        const key = (() => {
            switch(stem) {
                case "origination":
                    return "policyTranscriptData";
                case "servicing":
                    return "messageLog";
                case "bids":
                    return "data"
            }
        })();

        try {
            const response = await session?.env?.functions?.buildFetchRequest(fetchPath, params);
            const resData = await response.json();

            if (resData.status === 200) {
                let messages = resData?.[key];

                return messages;
            } else if (resData.status === 500) {
                return null;  // Explicitly return null if no activities found
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error("Error loading messages:", error);
            updateLoadingHandler("messages", false, 9);
            return null;  // Explicitly return null in case of error
        }
    };

    return {
        updateReadStatus,
        loadTimeline,
        loadServicingPremiums,
        loadShareLink,
        loadLifeExpectancies,
        loadBids,
        loadMessages,
    }
}

export default Fetchhandler;