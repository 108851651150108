import React from 'react';
import { useState, useEffect, useRef } from 'react';

import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ModeCommentTwoToneIcon from '@mui/icons-material/ModeCommentTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import CircularProgress from '@mui/material/CircularProgress';

function MessagesTab(props){
    const session = props?.session;
    const stem = props?.stem;
    const messages = Object?.values(props?.messages || {}).sort((a, b) => b?.timeStamp - a?.timeStamp);
    const currentCase = props?.currentCase;
    const activeStatus = messages?.length ? "active" : "inactive";
    const user = session?.user?.data;

    const [timeStamp, setTimeStamp] = useState({
        value : undefined,
        isLastMessage : false,
    });
    const [sendingMessage, setSendingMessage] = useState('');

    const sendingMessageInput = useRef(null);
    const conversation = useRef(null);

    const sendMessage = (directMessage) => {
        setSendingMessage('');
        const currentTime = new Date().toISOString();
        const fetchPath = (() => {
            switch(stem) {
                case "origination":
                    return "sendMessage";
                case "servicing":
                    return "servicing/sendMessage";
                case "bids":
                    return "marketplace/sendMessage"
            }
        })();

        const params = (() => {
            switch(stem) {
                case "origination":
                    return {
                        "relatedPolicyID" : currentCase?.data?.relatedPolicyID,
                        "message" : directMessage ?? sendingMessage,
                        "nameOfSender" : user.firstName + " " + user?.lastName,
                        "emailOfSender" : user?.email,
                    };
                case "servicing":
                    return {
                        "policyID" : currentCase?.data?.relatedPolicyID,
                        "message" : directMessage ?? sendingMessage,
                        "nameOfSender" : user?.firstName + " " + user?.lastName,
                    };
                case "bids":
                    return {
                        "taskID" : currentCase?.data?.recordID,
                        "content" : directMessage ?? sendingMessage,
                    };
            }
        })();

        const tempMessages = [...messages];

        if(!directMessage){
            tempMessages.push({
                content: sendingMessage,
                timeStamp: currentTime,
                nameOfSender: params?.[stem]?.nameOfSender ?? user?.firstName + " " + user?.lastName,
                type: params?.[stem]?.type ?? "Message",
                originator: "Customer",
                loading: true,
            });
        }

        session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}].messages`, tempMessages);
        scrollToBottom();

        session?.env?.functions?.buildFetchRequest(fetchPath, params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                const updatedMessages = tempMessages.map(message =>
                    message.timeStamp === currentTime ? { ...message, loading: false } : message
                );

                session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}].messages`, updatedMessages);
            }else{
                const updatedMessages = tempMessages.map(message =>
                    message.timeStamp === currentTime ? { ...message, loading: "failed" } : message
                );

                session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}].messages`, updatedMessages);
            }
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const handleInputChange = (e) => {
        setSendingMessage(e.target.value);
        adjustTextareaHeight();
    };

    const adjustTextareaHeight = () => {
        if (sendingMessageInput.current) {
            sendingMessageInput.current.style.height = 'auto';
            sendingMessageInput.current.style.height = `${Math.min(sendingMessageInput.current.scrollHeight, 120)}px`;
        }
    };
    
    const message = (data, previousMessage, lastMessage) => {
        const config = {
            Customer: { sender: "external", position: "cR", padding: "e" },
            Internal: { sender: "internal", position: "cL", padding: "s" }
        };
    
        const { sender, position, padding } = config[data?.originator] || config["Internal"];
        const nameOfSender = previousMessage?.nameOfSender !== data?.nameOfSender;
        const currentDate = session?.env?.functions?.reformatDate(data?.timeStamp ?? data?.dateCreated, "date");
        const previousDate = previousMessage
            ? session?.env?.functions?.reformatDate(previousMessage?.timeStamp ?? previousMessage?.dateCreated, "date")
            : null;
        const newDay = currentDate !== previousDate || !previousMessage;
        const visibleData = timeStamp?.value === (data?.timeStamp ?? data?.dateCreated) || nameOfSender;

        return (
            <>
                {newDay && (
                    <div className="header f cC" key={`${data?.timeStamp ?? data?.dateCreated}-newDay`}>
                        <div className="cC g dP bR bold">
                            {session?.env?.functions?.reformatDate(data?.timeStamp ?? data?.dateCreated)}
                        </div>
                    </div>
                )}
                <div
                    key={`${data?.timeStamp ?? data?.dateCreated}-${sender}`}
                    className={`message ${sender} ${padding} ${position} g f pR dG fC${data?.loading === "failed" ? " failed" : ''}${data?.loading === true ? " sending" : ''}`}
                >
                    {data?.loading === "failed" &&
                        <div className="error f tC g">
                            <div className="retry p g cC" onClick={()=>{sendMessage(data?.content ?? data?.message)}}>
                                Failed to send
                                <ReplayTwoToneIcon/>
                            </div>
                        </div>
                    }
                    <div
                        className={`context g f dG ${position}`}
                        onMouseEnter={() => setTimeStamp({value : (data?.timeStamp ?? data?.dateCreated), isLastMessage : lastMessage})}
                        onMouseLeave={() => setTimeStamp({value : undefined, isLastMessage : false})}
                    >
                        {visibleData &&
                            <div className={`data g ${position} ${padding}`}>
                                {nameOfSender && (
                                    <div className="nameOfSender f bold">{data?.nameOfSender}</div>
                                )}
                                {timeStamp?.value === (data?.timeStamp ?? data?.dateCreated) &&
                                    <div className={`timeStamp`}>
                                        {session?.env?.functions?.reformatDate((data?.timeStamp ?? data?.dateCreated), "timestamp")}
                                    </div>
                                }
                            </div>
                        }
                        <div className="content g bR dP pR">
                            {data?.content ?? data?.message}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    function scrollToBottom(){
        if (conversation?.current){
            conversation.current.scrollTop = conversation?.current?.scrollHeight;
        } 
    }

    useEffect(() => {
        adjustTextareaHeight();
    }, [sendingMessage]);

    useEffect(() => {
        scrollToBottom();
    }, [, messages?.length]);

    useEffect(() => {
        if (timeStamp?.value && timeStamp?.isLastMessage) {
            scrollToBottom();
        }
    }, [timeStamp]);

    return (
        <div
            key="messages"
            ref={conversation}
            className={`messages g f pR s e dG ${activeStatus}`}
        >
            {props?.loadingStatus || !messages?.length ?
                <div className="f cC">
                    {props?.loadingStatus ?
                        <CircularProgress/>
                    :
                        <>
                            <div className="g dG">
                                <ModeCommentTwoToneIcon/>
                                <div className="gC2">
                                    {`Have any questions?`}
                                </div>
                            </div>
                        </>
                    }
                </div>
            :
                <div className="transcript g dG t b">
                    {
                        messages.map((data, index) => (
                            <React.Fragment key={data?.timeStamp || index}>
                                {message(data, messages?.[index - 1], index === messages.length - 1)}
                            </React.Fragment>
                        ))
                    }
                </div>
            }
            <div className={`actionBar f g bC dP dG bR${sendingMessage ? " eligible" : ''}`}>
                <textarea
                    ref={sendingMessageInput}
                    className="sendingMessage fR fC dP oA"
                    value={sendingMessage}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    rows={1}
                    placeholder="Type your message..."
                />
                <div
                    className={`send btn f cC fC fR g${sendingMessage ? " p" : ''}`}
                    onClick={()=> {sendingMessage && sendMessage()}}
                >
                    <SendTwoToneIcon/>
                </div>
            </div>
        </div>
    );
}

export default MessagesTab;