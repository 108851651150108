import { React, useState, useEffect, useRef } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';

function LifeExpectancies(props){
    const session = props?.session;
    const leData = props?.leData;

    return (
        <>
            <div className="header g fR bR cC f fC bold dP">
                <div className="columnHeaders f g cC dG">
                    <div className="f cL">
                        <div className="column g fC dG cC" title="Name">
                            <BadgeTwoToneIcon style={{color: "#607d8b"}}/>
                            <div className="cL gCW f">
                                Name
                            </div>
                        </div>
                    </div>
                    <div className="column g fC dG cC" title="LE Month">
                        <StraightenTwoToneIcon style={{color: "#ff9800"}}/>
                        <div className="cL gCW f">
                            LE Month
                        </div>
                    </div>
                    <div className="column g fC dG cC" title="Source">
                        <SourceTwoToneIcon style={{color: "#4caf50"}}/>
                        <div className="cL gCW f">
                            Source
                        </div>
                    </div>
                    <div className="f cR">
                        <div className="column g fC dG cC" title="Date">
                            <CalendarMonthTwoToneIcon style={{color: "#ba68c8"}}/>
                            <div className="cL gCW f">
                                Date
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`lifeExpectancies g f dG${!props?.leData?.length ? " inactive" : ''}`}>
                <div className="table f g dG">
                    {props?.loadingStatus ? (
                        <div className="loading bR g cC f">
                            <CircularProgress />
                        </div>
                    ) : props?.leData?.length ? (
                        Object.keys(leData).map((key) => (
                            <div key={key} className="rowContainer g">
                                <div className="f cL dG">
                                    {leData[key].secondaryInsured === "No" ? (
                                        <>
                                            <PersonOutlineTwoToneIcon />
                                            <span>Primary</span>
                                        </>
                                    ) : (
                                        <>
                                            <PeopleAltTwoToneIcon />
                                            <span>Secondary</span>
                                        </>
                                    )}
                                </div>
                                <div
                                    key={leData[key]?.recordID}
                                    className="row g fR bR cC"
                                >   
                                    <div className="f g fC fR dP">
                                        <div className="cell g cL gCW">
                                            {leData[key].insuredFullName ?? "N/A"}
                                        </div>
                                    </div>
                                    <div className="f g fC fR dP">
                                        <div className="cell g cC gCW bold">
                                            {leData?.[key].leMonths ?? "N/A"}
                                        </div>
                                    </div>
                                    <div className="f g fC fR dP">
                                        <div className="cell g cC gCW">
                                            {leData?.[key].leSource ?? "N/A"}
                                        </div>
                                    </div>
                                    <div className="f g fC fR dP">
                                        <div className="cell g cR gCW">
                                            {leData?.[key]?.leReportDate ?
                                                session?.env?.functions?.reformatDate(leData?.[key]?.leReportDate)
                                            :
                                                "N/A"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="f cC">
                            No life expectancy data to display
                        </div>
                    )}
                </div>
            </div>
        </>
      );
}

export default LifeExpectancies;