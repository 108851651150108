import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './envTemplate.css';
import './CSS/Overlays.css';
import './Components/Kanban/Kanban.css';
import './CSS/Reporting.css';
import './CSS/Tertiary.css';
import './CSS/Policies.css';
import './CSS/Dashboard.css';
import './CSS/SingleView.css';
import './CSS/InstanceTabs/InstanceTabs.css';
import './CSS/envTemplate.css';
import './CSS/Inputs.css';
import './CSS/InstanceTabs/Timeline.css';
import './CSS/InstanceTabs/LifeExpectancies.css';
import './CSS/InstanceTabs/Documents.css';
import './CSS/InstanceTabs/PremiumSchedule.css';
import './CSS/InstanceTabs/StatusDetails.css';
import './CSS/InstanceTabs/DetailsTab.css';
import './CSS/InstanceTabs/BidsTab.css';
import './CSS/InstanceTabs/Messages.css';
import './CSS/Bids.css';
import './CSS/Charts.css';
import './CSS/Buttons.css';

import './dynamic.css';
import './dynamicMin.css';
import './dynamicMid.css';
import './dynamicMobile.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);
