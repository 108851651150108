import React, { useState, useEffect, useRef } from 'react';


import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import MarkEmailUnreadTwoToneIcon from '@mui/icons-material/MarkEmailUnreadTwoTone';

import TimelineHandler from '../../Timeline2/TimelinePostHandler.js';
import StatusIcon from '../../StatusIcons2.js';
import { getStatusData } from '../../StatusIcons2.js';

function StatusDetails(props){
    const session = props?.session;
    const { currentCase, timelineItem, branch, stem} = props?.overlayProps;

    const [readStatus, setReadStatus] = useState(timelineItem?.readStatus ?? undefined);
    const statusKeyHandler = () => {
        switch (stem) {
            case "origination":
                return "policyStatus";
            case "servicing":
                return "status";
            case "bids":
                return "taskShippingStatus";
            case "tertiary":
                return "shippingStatus";
            case "leads":
                return "status";
            default:
                return undefined;
        }
    };

    const status = timelineItem?.[statusKeyHandler()];
    const type = timelineItem?.type;
    const originator = timelineItem?.originator;
    const statusData = getStatusData(status, type);

    const friendlyTerm = () => {
        console.log(type, status);
        if(type === "Response - Request") return "Response";
        return type ?? status ?? "Unknown Status"
    }

    const icon = () => {
        // Check if the icon is an object (which means we need to use checkStatus)
        if (statusData?.icon && typeof statusData?.icon === 'object' && timelineItem?.checkStatus) {
            const currentIcon = statusData?.icon?.[timelineItem?.checkStatus];
            const currentColor = statusData?.color?.[timelineItem?.checkStatus] || "dimgray"; // Default to "dimgray" if no color found
            
            return currentIcon && React.isValidElement(currentIcon) 
                ? React.cloneElement(currentIcon, { sx: { color: currentColor } }) 
                : <StatusIcon status={"default"} />;
        }
    
        // If icon is not an object or no specific status, use the direct icon from statusData
        if (React.isValidElement(statusData?.icon)) {
            return React.cloneElement(statusData?.icon, { sx: { color: statusData?.color || "dimgray" } });
        }
    
        // Fallback to default StatusIcon if none of the conditions are met
        return <StatusIcon status={"default"} />;
    };
    
    const content = () => {
        if(type === "Status") return description(timelineItem?.[statusKeyHandler()]);
        if(type === "Note") return timelineItem?.content ?? "No note content to display."
        if(type === "Response - Request") return timelineItem?.content ?? "No note content to display."
        if(type === "Request") return timelineItem?.content ?? "No note content to display."
        if(type === "Bid"){
            const bidData = () => {
                // Default to using timelineItem if values exist
                const bidAmount = timelineItem?.bidAmount || timelineItem?.content?.split(",")[0]?.trim();
                const bidRDB = timelineItem?.bidRDB || timelineItem?.content?.split(",")[1]?.trim();
            
                // Extract the message (everything after the second comma)
                const message = timelineItem?.content?.split(",").slice(2).join(",").trim();
            
                return { bidAmount, bidRDB, message };
            };

            return (
                <>
                    <div className="f tL">
                        {bidData()?.message}
                    </div>
                    <div className="offerData g f cL dG">
                        <div className="offer f cL g dG lH">
                            Amount :
                            <div className="dataPoint bR bold">
                                {session?.env?.functions?.convertIntToCurrency(bidData()?.bidAmount)}
                            </div>
                        </div>
                        {!timelineItem?.bidRDB &&
                            <div className="offer f cL g dG lH">
                                RDB :
                                <div className="dataPoint bR bold">
                                    {session?.env?.functions?.convertIntToCurrency(bidData()?.bidRDB)}
                                    </div>
                                </div>
                            }
                    </div>
                </>

            )
        }
        return description(timelineItem?.[status]);
    }

    const checkStatus = () => {
        const checkStatus = timelineItem?.checkStatus;
        if(!checkStatus === undefined) return undefined;

        if(checkStatus === "Completed" && type === "Request") return "Completed";
        if(checkStatus === "Completed" && type === "Bid") return "Accepted";
        if(checkStatus === "Open") return "Pending";
        if(checkStatus === "Denied" && type === "Request") return "Declined";
        if(checkStatus === "Denied" && type === "Bid") return "Declined";
    }

    const description = (status) => {
        let description;

        switch (status) {
            case "On Hold":
                description = "Awaiting definition.";
                break;
            case "Pre-Priced":
                description = "Preliminary stage where we internally estimate pricing and life expectancies.";
                break;
            case "Lead":
                description = "Policy was uploaded to the Marketplace.";
                break;
            case "InitialLead":
                description = "A lead has been sent to your account to follow up with.";
                break;
            case "Contact Attempted":
                description = "Client was reached out to. Awaiting response.";
                break;
            case "Failed to Contact":
                description = "Client was unable to be reached after attempted contact.";
                break;
            case "Client Not Interested":
                description = "Client decided not to sell or continue working with us.";
                break;
            case "Unqualified Closed":
                description = "Policy has been deemed as unqualified to continue.";
                break;
            case "Application Sent":
                description = "An initial application has been sent out and awaiting its return.";
                break;
            case "Meds/Illustration Ordered":
                description = `Medical records, policy documents and illustrations have been ordered. Subsequent steps follow when the requested documents have returned.`;
                break;
            case "Underwriting (LE's)":
                description = `The process of selecting risks for insurance and classifying them according to their degrees of insurability so that the appropriate rates may be assigned. The process also includes rejection of those risks that do not qualify.`;
                break;
            case "Pricing / Valuation":
                description = "Pricing is being decided on the basis of estimations, as well as, returned documents.";
                break;
            case "Shipped":
                description = "Your policy information has been sent out to funders to find suitable interests.";
                break;
            case "Bids":
                description = "This is the period of which reviewing of offers and bids take place.";
                break;
            case "Bidding":
                description = "This case has been moved into the bidding state.";
                break;
            case "Offer Letter Out / CRF":
                description = "Offer Letter and Contract Request forms have been sent out and awaiting their signed return.";
                break;
            case "Offer Letter In":
                description = "Offer Letter and Contract Request forms have been returned.";
                break;
            case "Contracts Out":
                description = "Contracts and other documentation has been shipped out.";
                break;
            case "Contracts Back (Digital)":
                description = "Available digital contracts and documentation has been signed and returned.";
                break;
            case "Contracts Back":
                description = "Available physical contracts and documentation has been signed and returned.";
                break;
            case "Escrow / At Carrier":
                description = "Escrow funded; Waiting for carrier change forms to be processed.";
                break;
            case "Rescission":
                description = "This is the waiting period before everything gets finalized.";
                break;
            case "Matured During Rescission":
                description = "Policy has reached its maturity date and the insured party has passed away during a period of rescission.";
                break;
            case "Invoicing":
                description = "The buyer and seller have agreed on the terms of the sale and the seller has or plans to transfer ownership of the policy to the buyer.";
                break;
            case "Closed Won":
                description = "Congratulations! Everything has been completed.";
                break;
            case "Closed Lost":
                description = "Policy dismissed due to some underlying reason.";
                break;
            case "Closed Lost to Competitor":
                description = "Policy has been closed.";
                break;
            case "Closed Lost with Bid":
                description = "Policy has been closed.";
                break;
            case "Lost with bid":
                description = "Policy has been closed, as the case lost with an existing bid.";
                break;
            case "Closed Lost no bid":
                description = "No bid was provided and/or approved.";
                break;
            case "Sent to 3rd Party":
                description = "Policy has been sent to a 3rd party.";
                break;
            case "Client Deceased":
                description = "Client passed away prior to any finalization.";
                break;
            case "Follow Up":
                description = "A follow up reminder has been created with a designated date and time.";
                break;
            case "Contact Attempted 1":
                description = "An attempt of making contact to the client has not been successful.";
                break;
            case "Contact Attempted 2":
                description = "A 2nd attempt of making contact to the client has not been successful.";
                break;
            case "Contact Attempted 3":
                description = "A 3rd attempt of making contact to the client has not been successful.";
                break;
            case "Application Received":
                description = "The initial application has been received.";
                break;
            case "Declined":
                description = "This case has been declined.";
                break;
            case "Declined - No Bid":
                description = "This case has been declined and you've not proceeded with any offer.";
                break;
            case "Phase II":
                description = "This case has progressed to phase in which you're sharing your potential interest in this case.";
                break;
            case "Under Review":
                description = "You have placed this case in the Under Review Stage, notifying us of your potential interest.";
                break;
            case "Portfolio Sent":
                description = "A new portfolio has been created and sent to your account.";
                break;
            default:
                description = "This status has not been provided a definition at this time.";
                break;
        }
    
        return description;
    };

    const updateReadStatus = (newReadStatus) => {
        const tempCase = {...currentCase?.data};

        const fetchPath = (() => {
            switch(stem) {
                case "origination":
                    return "updateReadStatus";
                case "servicing":
                    return "servicing/updateReadStatus";
                case "tertiary":
                    return "tertiary/updateReadStatus";
                case "bids":
                    return "marketplace/updateReadStatus"
            }
        })();

        const params = (() => {
            const baseParams = {
                "type": "single",
                "unread": newReadStatus,
                "activityID": timelineItem?.recordID,
            };
        
            switch (stem) {
                case "origination":
                    return {
                        ...baseParams,
                        "relatedID": currentCase?.data?.relatedPolicyID,
                        "type" : "policy"
                    };
                case "servicing":
                    return baseParams;
                case "tertiary":
                    return {
                        ...baseParams,
                        "recordBidID": currentCase?.data?.recordID,
                    };
                case "bids":
                    return {
                        ...baseParams,
                        "taskID": currentCase?.data?.taskID,
                    };
            }
        })();

        // console.log(fetchPath, params);

        tempCase.timeline.list = tempCase.timeline.list.map(item => 
            item.recordID === timelineItem?.recordID ? { ...item, readStatus: newReadStatus } : item
        );

        if (newReadStatus === false) {
            tempCase.unread.push(timelineItem);
        } else {
            tempCase.unread = tempCase.unread.filter(item => item.recordID !== timelineItem?.recordID);
        }

        const casePath = `${branch}.${stem}[${currentCase?.index}]`;
        // session?.set("case", [`${casePath}.unread`, `${casePath}.timeline.list`], [tempCase?.unread, tempCase?.timeline?.list]);

        session?.set("case", casePath, tempCase);
        session?.env?.functions?.buildFetchRequest(fetchPath, params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                console.log(resData);
            }else{
                console.log("Failed", resData);
            }
        });
    };

    return (
        <div className="statusDetails g s e b dG" onClick={()=>{console.log(props)}}>
            {(timelineItem?.readStatus !== undefined || timelineItem?.nameOfSender) &&
                <div className="topBar f g">
                    {timelineItem?.readStatus !== undefined && (timelineItem?.originator === undefined || timelineItem?.originator !== "Customer") &&
                        <div className="f cL gC1">
                            <div
                                className={`btn ${readStatus ? "read" : "unread"} bold bR ns g dG fR p cC s e`}
                                onClick={()=>{
                                    setReadStatus(!readStatus);
                                    updateReadStatus(!readStatus);
                                }}
                            >
                                {readStatus ?
                                    <MarkEmailUnreadTwoToneIcon/>
                                :
                                    <MarkEmailReadTwoToneIcon/>
                                }
                                <div className="gC2">
                                    {readStatus ? "Mark Unread" : "Mark Read"}
                                </div>
                            </div>
                        </div>
                    }
                    {timelineItem?.nameOfSender &&
                        <div className="bC gC2">
                            {`Created by: ${timelineItem?.nameOfSender}`}
                        </div>
                    }
                </div>
            }
            <TimelineHandler
                session={session}
                branch={branch}
                stem={stem}
                currentCase={currentCase}
                timelineItem={timelineItem}
                detailed={true}
                description={description(timelineItem?.[statusKeyHandler()])}
                readStatus={readStatus}
            />
        </div>
    );
}
export default StatusDetails;