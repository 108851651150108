import React, { useEffect, useState } from 'react';

function PolicyVars(props){
    const localPolicies = JSON.parse(localStorage.getItem('policies')) || {};
    const localOrigination = localPolicies.origination || {};
    const localServicing = localPolicies.servicing || {};

    const originationReport = {
        "recordID": 987654,
        "active": true,
        "relatedUserID": 1,
        "userFullName": "Matthew Olivos",
        "userAccountID": 10,
        "query": "((recordID not_blank 'true') AND (shippingStatus match 'Bidding' OR shippingStatus match 'Closed Won' OR shippingStatus match 'Closing' OR shippingStatus match 'Declined' OR shippingStatus match 'Does Not Fit Parameters' OR shippingStatus match 'In Conflict' OR shippingStatus match 'Initial Assessment' OR shippingStatus match 'Lost with bid' OR shippingStatus match 'No Bid' OR shippingStatus match 'Phase II' OR shippingStatus match 'Request LEs / Datatape' OR shippingStatus match 'Requested Info' OR shippingStatus match 'Shipped' OR shippingStatus match 'Under Review' OR shippingStatus match 'Won'))",
        "stem": "tertiary",
        "branch": "bids",
        "criteria": {
            "existing": [
            {
                "formType": "generatedList",
                "criterion": {
                    "match": undefined
                },
                "groupID": 0,
                "groupOperator": "AND",
                "inlineOperator": "AND",
                "attr": "portfolioName"
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Bidding"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus"
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Closed Won"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Declined"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Closing"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Does Not Fit Parameters"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "In Conflict"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Initial Assessment"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Lost with bid"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "No Bid"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Phase II"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Request LEs / Datatape"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Requested Info"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Shipped"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            },
            {
                "formType": "generatedList",
                "criterion": {
                    "match": "Under Review"
                },
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "OR",
                "attr": "shippingStatus",
                "relativeKey" : 1,
            }],
            "groupBy": ""
        },
        "columns": [
            {
                "id": "0",
                "columnName": "dateCreated",
                "friendlyTerm": "Date Created",
                "frozen": false,
                "editable": false,
            },
            {
                "id": "2",
                "columnName": "shippingStatus",
                "friendlyTerm": "Shipping Status",
                "frozen": false,
                "editable": true,
            },
            {
                "id": "3",
                "columnName": "policyNumber",
                "friendlyTerm": "Policy Number",
                "frozen": false,
                "editable": false,
            },
            {
                "id": "4",
                "columnName": "primaryFullName",
                "friendlyTerm": "Primary Full Name",
                "frozen": false,
                "editable": false,
            },
            {
                "id": "5",
                "columnName": "faceValue",
                "friendlyTerm": "Face Value",
                "frozen": false,
                "editable": false,
            },
            {
                "id": "6",
                "columnName": "bidDate",
                "friendlyTerm": "Bid Date",
                "frozen": false,
                "editable": true,
            },
            {
                "id": "7",
                "columnName": "grossBid",
                "friendlyTerm": "Gross Bid",
                "frozen": false,
                "editable": true,
            }
        ],
        "generated" : true,
        // "editable": "always",
        "scheduled": false,
        "selectAll": false,
        "groupID": 0,
        "referenceRecordID": null,
        "details": {
            "name": "Portfolio 1",
            "dateCreated": "2024-02-09T19:50:55Z",
            "description": "",
            "system": false,
            "startDate": "",
            "endDate": "",
            "scrollType": "pagination",
            "displayType": "",
            "viewAccessList": [
                "team"
            ],
            "editAccessList": [
                "team"
            ]
        },
        "editingRowValues" : [],
        "specialColumns" : {
        "rowIndex" : false,
        "select" : false,
        "view" : false,
        },
        "selectionState" : {
        "selectedRows": [],
        "lastClickedRowIndex": null
        },
    }

    const [data, setData] = useState({
        origination : {
            selectedReport : undefined,
            downloadFileType : localOrigination.downloadFileType || "XLSX",
        },
        servicing : {
            selectedReport : undefined,
            downloadFileType : localServicing.downloadFileType || "XLSX",
            preferredPremiumsFormat : localServicing.preferredPremiumsFormat || "schedule",
        },
    });

    const functions = {

    }

    const policyVars = {
        data,
        setData,
        functions,
    }

    return policyVars;
};

export default PolicyVars;