import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import './Login.css';

import Logo from '../Images/AbacusLifeLogo.png';

import InputField from '../Components/InputField';
import TermsOfService from './TOS.js';

import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import MailLockRoundedIcon from '@mui/icons-material/MailLockRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { authorizeRequest } from '../envHandler.js';

function Login(props){
    const sessionVars = props?.sessionVars;
    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const ac = params.get("ac");
    const e = params.get("e");

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [totalActive, setTotalActive] = useState();
    const [totalClosed, setTotalClosed] = useState();
    const [errorStatus, setErrorStatus] = useState();
    const [displayActive, setDisplayActive] = useState();
    const [displayClosed, setDisplayClosed] = useState();
    const [TOSView, setTOSView] = useState(false);

    const [userData, setUserData] = useState();
    const requirements = [
        { key: "capitalLetter", label: "1 Capital Letter" },
        { key: "lowerCase", label: "1 Lowercase" },
        { key: "symbol", label: "1 Symbol" },
        { key: "number", label: "1 Number" },
        { key: "length", label: "At least 8 Characters" },
        { key: "matchingPasswords", label: "Matching Passwords" },
    ];

    const [activity, setActivity] = useState({
        currentPage : "login",
    });

    const [data, setData] = useState({
        authorization : '',
        email : '',
        password : '',
        passwordConfirmation : ''
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmission();
        }
    };

    function updateInput(evt){
        setData(prev => ({...prev, [evt.target.name]: evt.target.value }));
    }

    function handleSubmission(){

        if(activity.currentPage === "login"){
            if(eligibility(["password", "email"])){
                validateUser();
            }
        }

        if(activity.currentPage === "reset"){
            if(eligibility(["email"])){
                requestTwoFactor(true);
            }
        }

        if(activity.currentPage === "create"){
            if (eligibility(["password", "passwordConfirmation"]) && requirementStatus("all")) {
                attemptTwoFactor(ac);
            }
        }

        if(activity.currentPage === "existingReset"){
            if (eligibility(["password", "passwordConfirmation", "authorization"]) && requirementStatus("all", true)) {
                attemptTwoFactor(data.authorization, data.email, true);
            }
        }

        if(activity.currentPage === "twoFactor"){
            if(eligibility(["authorization"])){
                attemptTwoFactor(data.authorization, data.email);
            }
        }
    }

    const updateUseState = (setter, attr, value) => {
        setter((prev) => ({
          ...prev,
          [attr]: value,
        }));
    }

    function eligibility(attrs) {
        for (const attr of attrs) {
            if (data[attr] === '') {
                return false;
            }
        }

        return true;
    }

    function requirementStatus(req, includeAuth) {
        const password = data?.password || '';
        const passwordConfirmation = data?.passwordConfirmation || '';
      
        const checks = {
            length: password.length >= 8,
            capitalLetter: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            symbol: /[\W_]/.test(password),
            number: /\d/.test(password),
            matchingPasswords: password !== '' && password === passwordConfirmation,
        };

        if (req === "all") {
            return (
                checks.length &&
                checks.capitalLetter &&
                checks.lowerCase &&
                checks.symbol &&
                checks.number &&
                checks.matchingPasswords &&
                (includeAuth ? data.authorization !== '' : true)
            );
        }

        if (req.includes("length") && !checks.length) {
            return false;
        }

        if (req.includes("capitalLetter") && !checks.capitalLetter) {
            return false;
        }

        if (req.includes("lowerCase") && !checks.lowerCase) {
            return false;
        }

        if (req.includes("symbol") && !checks.symbol) {
            return false;
        }

        if (req.includes("number") && !checks.number) {
            return false;
        }

        if (req.includes("matchingPasswords") && !checks.matchingPasswords) {
            return false;
        }

        return true;
    }

    function passwordRequirements() {
        return (
            <div className="prompt alt f cC g">
                <div className="f cC bold">
                    Password must include:
                </div>
                {requirements.map((requirement) => (
                    <div className="g f cL fR" key={requirement.key}>
                        <div className={`f cC${requirementStatus(requirement.key) ? " complete" : ''}`}>
                            {requirementStatus(requirement.key) ?
                                <CheckRoundedIcon />
                                :
                                <HighlightOffRoundedIcon />
                            }
                        </div>
                        <div className="f s cL">
                            {requirement.label}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function requestTwoFactor(forgotPassword){
        setLoadingStatus(true);
        const paramVals = {
            email : data?.email,
            type : forgotPassword ? "passwordReset" : "signIn",
            // "authCode": authorization?.trim(),
            ...(forgotPassword ? {} : { password : data?.password })
        }

        // sessionVars?.envVars?.functions?.buildFetchRequest("twoFactor", paramVals)
        session?.env?.functions?.buildFetchRequest("twoFactor", paramVals)
        .then(response => response.json())
        .then(resData => {
            setLoadingStatus(false);
            if (resData.status === 200) {
                updateUseState(setActivity, "currentPage", forgotPassword ? "existingReset" : "twoFactor");
            } else {
                setErrorStatus(resData.message);
            }
        })
    }

    if (ac && e && activity.currentPage !== "create") {
        updateUseState(setActivity, "currentPage", "create");
    }

    function attemptTwoFactor(authorization, email, reset){
        setLoadingStatus(true);
        let paramVals = {
            "authType" : e || reset ? "passwordReset" : "default",
            // "validationCode": authorization?.trim(),
            // Remove authCode when updating login system
            "authCode": authorization?.trim(),
            "emailCode": authorization?.trim(),
            ...userData,
            ...((e || reset) && {
                "email": e, 
                "password":  data.password,
                "retypePassword": data.passwordConfirmation,
            }),
            ...(email && {
                "email": email,
            })
        };

        // sessionVars?.envVars?.functions?.buildFetchRequest("attemptAuthorization", paramVals)
        session?.env?.functions?.buildFetchRequest("attemptAuthorization", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                const redirectLink = sessionStorage.getItem('redirectLink');
                const sessionUser = {
                    ...resData.authReply,
                    sessionToken : resData?.sessionToken,
                    accountData : resData?.accountData,
                    application : "AMP",
                }

                const homepage = props?.session?.navigation?.functions?.homepage(sessionUser);

                props.setSessionUser(sessionUser);
                window.localStorage.setItem( 'sessionUser', JSON.stringify(sessionUser));
                session?.set("user", null, sessionUser);
                // session?.user?.functions?.loadUser(sessionUser);
                // const navigation = {...session?.navigation?.data}
                const instance = {view : homepage.replace(/^\/|\/$/g, ''), "instance" : "all"};
                // navigation.history.push(instance);
                session?.set("navigation",
                    ["currentInstance", "history"],
                    [instance, instance]
                );
                if (redirectLink) {
                  sessionStorage.removeItem('redirectLink');
                }
                navigate(redirectLink ?? homepage);

            }else{
                setErrorStatus(resData.message);
            }
        })
    }

    function validateUser(){
        setLoadingStatus(true);
        let paramVals = {
            "email" : data?.email,
            "password" : data?.password,
        };

        // sessionVars?.envVars?.functions?.buildFetchRequest("validateUser", paramVals)
        session?.env?.functions?.buildFetchRequest("validateUser", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                setUserData({
                    ...resData.profileData,
                    validationCode : resData.validationCode
                });
                requestTwoFactor();
            }else{
                setErrorStatus(resData.message);
            }
        });
    }

    useEffect(() => {
        if (!ac && !e) {
            if (navigate && navigate.pathname !== "/login") {
                navigate("/login");
            }
        }
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ac, e, navigate]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [data, activity]);

    useEffect(() => {
        setErrorStatus();
    }, [activity.currentPage]);

    useEffect(() => {
        if(session?.user?.data?.reset){
            session?.set("user", "reset", undefined);
        }
        // if (sessionStorage.getItem('userLoggedOut')) {
        //     sessionStorage.removeItem('userLoggedOut');
        // }
    }, [session?.user?.data?.reset]);
    
    const userAgent = window.localStorage.getItem("deviceToken");

    useEffect(()=>{
        setTotalActive(props.account.totalActiveAUM);
        setTotalClosed(props.account.totalClosedAUM);
    }, [props.account]);

    function toggleTOSView(){
        if(TOSView){
            setTOSView(false);
        }else{
            setTOSView(true);
        }
    }
    function toggleTOSAgreement(){
        setTOSView(false);
    }

    return (
        <>
            {viewType === "full" &&
                <div key="loginWrapper" className="loginSplash g cC">
                    <div key={0} className="splashMessage g fC cC">
                        <div className="splashWelcomeMessage g fC cC">
                            <div className="splashMessageHeadline">
                                <div className="gCW">
                                    Abacus Marketplace
                                </div>
                            </div>
                            <div className="splashMessageSmallAlt">
                                <div className="gCW">
                                    is a transparent full-service platform
                                </div>
                            </div>
                            <div className="splashMessagMediumAlt">
                                <div className="gCW">
                                    linking 20+ Capital Partners
                                </div>
                            </div>
                            <div className="splashMessageMedium">
                                <div className="gCW">
                                    handling <b>Billions</b> in AUM
                                </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="splashBulletins fC g cC">
                            <div className="f cL">
                                • Home of several exclusive partnerships
                            </div>
                            <div className="f cL">
                                • No additional fees or costs
                            </div>
                            <div className="f cL">
                                • Closing & Compliance Reports
                            </div>
                            <div className="f cL">
                                • E & O coverage for qualifying advisers
                            </div>
                            <div className="f cL">
                                • Valuation and bid reporting
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="splashStatusData fC g cC">
                            <div className="header bold f cC">
                                Abacus Life Total Policy Value
                            </div>
                            <div className="data f cC">
                                <div className="statusTotalActive bold">
                                    Active
                                    <div className="statusActiveTotalValue">
                                        <div className="statusValueBlock">
                                            <RollingAnimation maxValue={totalActive} />
                                        </div>
                                    </div>
                                </div>
                                <div className="statusTotalClosed bold">
                                    Closed
                                    <div className="statusClosedTotalValue">
                                        <div className="statusValueBlock">
                                            <RollingAnimation maxValue={totalClosed} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div key="loginBlockWrap" className="f cC">
                <div className={"loginBlock bR fC g cC oH " + activity.currentPage}>
                    <div className="header g cC">
                        <img className="f cC" src={Logo}/>
                        <div className="f cL s bold">
                            Abacus Life
                        </div>
                    </div>
                    <div className="message f cC">
                        {activity.currentPage === "login" && "Welcome"}
                        {activity.currentPage === "reset" && "Password Reset"}
                        {activity.currentPage === "create" && "Password Set"}
                        {activity.currentPage === "twoFactor" && "Authorize"}
                        {activity.currentPage === "existingReset" && "Password Reset"}
                    </div>
                    <div className="divider">
                    </div>
                    <div className="wrap f tO">
                        <div className={"form g f cC fC dP " + activity.currentPage}>
                            {activity.currentPage === "login" &&
                                <>
                                    <div className="fields f g">
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["email"]) !== '' ? " active" : ''}`}>
                                                <EmailRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.email}
                                                placeholder={"Email"}
                                                functionPointer={updateInput}
                                                name="email"
                                                setter={setData}
                                            />
                                        </div>
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                                <PasswordRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.password}
                                                isPassword={true}
                                                functionPointer={updateInput}
                                                placeholder="Password"
                                                name="password"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                    {errorStatus &&
                                        <div className="errorStatus dP gR2">
                                            {errorStatus}
                                        </div>
                                    }
                                    <div className="options f cC g gR3 fC">
                                        <div className="passwordReset cC dP p bR" onClick={()=>{updateUseState(setActivity, "currentPage", "reset")}}>
                                            Forgot password?
                                        </div>
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress/>
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon g bR e f p${eligibility(["password", "email"]) ? " active" : " inactive"}`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Login
                                                </div>
                                                <div className="f cC">
                                                    <LoginRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {activity.currentPage === "reset" &&
                                <>
                                    <div className="f cL s">
                                        <div
                                            className="goBack g cC p s e"
                                            onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                        >
                                            <div className="back btn cC">
                                                <ArrowBackRoundedIcon/>
                                            </div>
                                            Go Back
                                        </div>
                                    </div>
                                    <div className="fields f g">
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["email"]) ? " active" : ''}`}>
                                                <EmailRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.email}
                                                placeholder={"Email"}
                                                functionPointer={updateInput}
                                                name="email"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                    <div className="f cC prompt">
                                        Provide the email that's linked to your account.
                                        We'll send a confirmation email with a reset password link.
                                    </div>
                                    <div className="f cC fR">
                                        <div className="lockIcon cC">{eligibility(["email"])}
                                            {eligibility(["email"]) ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>
                                            }
                                        </div>
                                    </div>
                                    {errorStatus &&
                                        <div className="errorStatus dP">
                                            {errorStatus}
                                        </div>
                                    }
                                    <div className="options f cC g fC">
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress/>
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon f g bR e p${eligibility(["email"]) ? " active" : " inactive"}`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Send Email
                                                </div>
                                                <div className="f cC">
                                                    <ForwardToInboxRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {(activity.currentPage === "create" || activity.currentPage === "existingReset") &&
                                <>
                                    <div className="f cL s">
                                        <div
                                            className="goBack g cC p s e"
                                            onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                        >
                                            <div className="back btn cC">
                                                <ArrowBackRoundedIcon/>
                                            </div>
                                            Go Back
                                        </div>
                                    </div>
                                    <div className="fields f g">
                                        {activity.currentPage === "existingReset" &&
                                            <div className="field g cC">
                                                <div className={`f bC${eligibility(["authorization"]) ? " active" : ''}`}>
                                                    <MailLockRoundedIcon/>
                                                </div>
                                                <InputField
                                                    value={data?.authorization}
                                                    placeholder={"Authorization Code"}
                                                    functionPointer={updateInput}
                                                    name="authorization"
                                                    setter={setData}
                                                />                                    
                                            </div>
                                        }
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                                <PasswordRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.password}
                                                isPassword={true}
                                                functionPointer={updateInput}
                                                placeholder="Password"
                                                name="password"
                                                setter={setData}
                                            />
                                        </div>
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["passwordConfirmation"]) ? " active" : ''}`}>
                                                <PasswordRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.passwordConfirmation}
                                                isPassword={true}
                                                functionPointer={updateInput}
                                                placeholder="Confirm Password"
                                                name="passwordConfirmation"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                    {passwordRequirements()}
                                    <div className="f cC fR">
                                        <div className="lockIcon cC">
                                            {
                                                requirementStatus("all", activity.currentPage === "existingReset") ? 
                                                    <LockTwoToneIcon/>
                                                :
                                                    <LockOpenRoundedIcon/>
                                            }
                                        </div>
                                    </div>
                                    {errorStatus &&
                                        <div className="errorStatus dP">
                                            {errorStatus}
                                        </div>
                                    }
                                    <div className="options f cC g fC">
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress/>
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon f g bR e${
                                                    eligibility(["password", "passwordConfirmation", activity.currentPage === "existingReset" ?
                                                    "authorization"
                                                    : null])
                                                    && requirementStatus("all", activity.currentPage === "existingReset") ?
                                                    " active p"
                                                    : " inactive"}`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Login
                                                </div>
                                                <div className="f cC">
                                                    <LoginRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {activity.currentPage === "twoFactor" &&
                                <>
                                    <div className="f cL s">
                                        <div
                                            className="goBack g cC p s e"
                                            onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                        >
                                        <div className="back btn cC">
                                                <ArrowBackRoundedIcon/>
                                            </div>
                                            Go Back
                                        </div>
                                    </div>
                                    <div className="fields f g">
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["authorization"]) ? " active" : ''}`}>
                                                <MailLockRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.authorization}
                                                placeholder={"Authorization Code"}
                                                functionPointer={updateInput}
                                                name="authorization"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                    <div className="f cC prompt">
                                        We've sent an authorization code to your email. Please provide the code to proceed with logging in.
                                    </div>
                                    <div className="f cC fR">
                                        <div className="lockIcon cC">{eligibility(["authorization"])}
                                            {eligibility(["authorization"]) ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>
                                            }
                                        </div>
                                    </div>
                                    {errorStatus &&
                                        <div className="errorStatus dP">
                                            Oops! The code you entered doesn't match the one we sent to your email.
                                        </div>
                                    }
                                    <div className="options f cC g fC">
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress/>
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon f g bR e p${eligibility(["authorization"]) ? " active" : " inactive"}`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Authorize
                                                </div>
                                                <div className="f cC">
                                                    <KeyRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {TOSView && null
                // <div key="TermsOfService" className="TOSWrapper">
                //     <div className="TOSInnerWrap">
                //         <div className="TOSTitle">
                //         { loginState === "loginModule" ? "Updated Terms of Service" : "Terms of Service" }
                //             <div className="TOSCloseBtn" onClick={()=> { toggleTOSView() }}>
                //                 <CloseIcon/>
                //             </div>
                //         </div>
                //         <div className="TOSContainer">
                //             <TermsOfService/>
                //         </div>
                //         <div className="TOSConfirmation">
                //             { loginState === "loginModule" ?
                //                 <div className={ TOSAgreement ? "TOSContinueBtn active" : "TOSContinueBtn" } onClick={ TOSAgreement ? ()=> { confirmTOSAgreement() } : null }>
                //                     <div>
                //                         Update
                //                     </div>
                //                 </div> :
                //                 null
                //             }
                //             <div className={"TOSConfirmationBtnContainer" + TOSAgreement} onClick={()=> { toggleTOSAgreement() }}>
                //                 <div className="TOSConfirmationBtn">{TOSAgreement ? <CheckBoxIcon/> : null }</div>
                //                 <div className="TOSConfirmationPrompt">I Agree</div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            }
        </>
    )
}
  
export default Login;


const RollingAnimation = ({ maxValue }) => {
    const [currentValue, setCurrentValue] = useState(0);
  
    useEffect(() => {
      let animationInterval;
      if (currentValue < maxValue) {
        const increment = maxValue / 60; // Increment in 1 second
        let accumulatedValue = 0;
  
        animationInterval = setInterval(() => {
          accumulatedValue += increment;
          const newValue = Math.min(Math.ceil(accumulatedValue), maxValue);
          setCurrentValue(newValue);
  
          if (newValue === maxValue) {
            clearInterval(animationInterval);
          }
        }, 1000 / 60); // 60 frames per second
      }
  
      return () => clearInterval(animationInterval);
    }, [maxValue]);
  
    return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };