import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineIcon from '@mui/icons-material/Timeline';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';

import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';
import CurrentActivity from '../InstanceTabs/CurrentActivity.js';
import TertiaryReportToggles from '../Components/Tertiary/TertiaryReportToggles.js';
import SingleView from '../Components/SingleView.js';
import LoadingScreen from '../Components/LoadingScreen.js';

function Tertiary(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const bids = props?.session?.bids;
    const baseData = bids?.data?.tertiary;
    const selectedReport = bids?.data?.tertiary?.selectedReport;
    const active = baseData?.selectedPortfolio?.name !== undefined;

    const reportType = reporting?.functions?.reportType(selectedReport?.stem);
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const editableReport = selectedReport?.editable === "yes" || selectedReport?.editable === "always";

    const [reportingView, setReportingView] = useState("list");
    const [loadingHandler, setLoadingHandler] = useState({ timeline: true, portfolios : true, shareLink : true,});
    const [currentPortfolio, setCurrentPortfolio] = useState(session?.bids?.data?.tertiary?.selectedPortfolio ?? undefined);

    const location = useLocation();
    const currentPath = session?.env?.functions?.currentPath(location);
    const updateSelectedReport = (portfolioName, data) => {
        if(session?.bids?.data?.tertiary?.selectedPortfolio?.recordID === data?.portfolioID  && session?.bids?.data?.tertiary?.selectedPortfolio?.fundID === data?.fundID){
            return;
        }
        const tertiary = {...baseData};

        const timeline = baseData?.portfoliios?.[data?.portfolioID]?.funds?.[data?.fundID]?.timeline ?? false;

        tertiary.selectedPortfolio = {
            name : data?.portfolioDisplayName,
            recordID : data?.portfolioID,
            portfolioName : data?.portfolioName,
            relatedAccountID : data?.relatedAccountID,
            fundID : data?.fundID,
            submissionDate : data?.submissionDate,
            timeline: timeline,
        }

        tertiary.selectedReport = updateCriteria({
            values : data?.portfolioName,
            formType : "generatedList",
            criterion : "match",
            attribute : "portfolioName",
            groupID : 0,
            groupOperator : "AND",
            inlineOperator : "AND",
        });

        tertiary.selectedReport = updateCriteria({
            values : data?.portfolioID,
            formType : "generatedList",
            criterion : "match",
            attribute : "relatedPortfolioID",
            groupID : 0,
            groupOperator : "AND",
            inlineOperator : "AND",
            relativeKey : 0,
        });

        tertiary.selectedReport = updateCriteria({
            values : data?.fundID,
            formType : "int",
            criterion : "match",
            attribute : "fundID",
            groupID : 0,
            groupOperator : "AND",
            inlineOperator : "AND",
            relativeKey : 0,
        });

        session?.set('bids', 'tertiary', tertiary, "here!");
        updateDropdown("multipleReports", {
            reportName : data?.portfolioDisplayName
        });
        session?.env?.functions?.updateLocalStorage("bids", "tertiary", "selectedPortfolio", "dataTape");
    }

    const [dropdowns, setDropdowns] = useState({
        reportDownload : {
            showMenu : true,
        },
        pagination : {
            showMenu : false,
            rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
            index : selectedReport?.lastPageIndex ?? 1,
        },
        multipleReports : {
            showMenu : false,
            reportName : baseData?.selectedPortfolio?.name,
            prompt : "Select Portfolio",
            inactive : !session?.bids?.data?.tertiary?.portfolios,
            nameList: generateReportNames() ?? ["N/A"],
            onClick : updateSelectedReport
        },
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    function downloadDataTape(){
        // Set loadingStatus to true when the download starts
        setPreferredFormat(prev => ({
          ...prev,
          dataTape: {
            ...prev.dataTape,
            loadingStatus: true
          }
        }));
  
        const params = {
          fundID: session?.bids?.data?.tertiary?.selectedPortfolio?.fundID,
          portfolioID: session?.bids?.data?.tertiary?.selectedPortfolio?.recordID,
        };
  
        session?.env?.functions?.buildFetchRequest(
          "tertiary/downloadDataTape", 
          params, 
          { 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
        )
        .then((blob) => {
          const objUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = objUrl;
          link.download = "DataTape.xlsx";
          link.click();
          URL.revokeObjectURL(objUrl);  // Optionally revoke the URL after download
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          // Set loadingStatus to false when the download is complete
          setPreferredFormat(prev => ({
            ...prev,
            dataTape: {
              ...prev.dataTape,
              loadingStatus: false
            }
          }));
        });

        session?.set(props?.branch, `${props?.stem}.preferredReportFormat`, "dataTape");
        session?.env?.functions?.updateLocalStorage("bids", props?.stem, "preferredReportFormat", "dataTape");
    }
    function downloadReport(){
        reporting?.functions?.downloadReport(session, selectedReport);
        session?.set(props?.branch, `${props?.stem}.preferredReportFormat`, "report");
        session?.env?.functions?.updateLocalStorage("bids", props?.stem, "preferredReportFormat", "report");
    }

    const [preferredFormat, setPreferredFormat] = useState({
        dataTape: {
          friendlyTerm: "Data Tape",
          format: "dataTape",
          loadingStatus: false,  // Default loading status
          onClick: () => downloadDataTape(),
        },
        report: {
          friendlyTerm: "Report",
          format: "report",
          onClick: () => downloadReport(),
        }
    });

    const updateLoadingHandler = (keys, value) => {
        setLoadingHandler((prevState) => {
            // Check if keys is an array or a single string
            if (Array.isArray(keys)) {
                // Create a new object with updated values for all keys
                const updatedState = keys.reduce((acc, key) => {
                    acc[key] = value;
                    return acc;
                }, {});

                return {
                    ...prevState,
                    ...updatedState,
                };
            } else if (typeof keys === "string") {
                // Update a single key
                return {
                    ...prevState,
                    [keys]: value,
                };
            }
      
          return prevState; // Return previous state if keys is not valid
        });
    };

    let lastX = null;

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    
    const calculateDimensions = (xPos) => {
        const containerWidth = document.querySelector('.tertiaryHub').offsetWidth;
        const minWidth = 300; // Minimum width in pixels for the right side
        const maxWidth = containerWidth - 506; // Maximum width for the right side
        const newWidth = containerWidth - xPos + 12; // Adjust this calculation as needed
    
        return { containerWidth, minWidth, maxWidth, newWidth };
    };
    
    const updateDimensions = (width) => {
        const localBids = JSON.parse(localStorage.getItem('bids')) || {};
        const localTertiary = localBids.tertiary || {};
        const dimensions = `1fr 8px ${width}px`;
        if(dimensions === localTertiary?.dimensions) return;

        session?.set('bids', 'tertiary.layout.dimensions', dimensions);
        session?.env?.functions?.updateLocalStorage("bids", "tertiary", "dimensions", dimensions);
    };
    
    const adjustDimensions = ({ newWidth, minWidth, maxWidth }) => {
        if (newWidth < minWidth) {
            updateDimensions(minWidth); // Set to the minimum width
        } else if (newWidth > maxWidth) {
        // } else if (newWidth > maxWidth && reportWidth <= 498) {
            updateDimensions(maxWidth); // Set to the maximum allowed width
        } else {
            updateDimensions(newWidth); // Dynamically adjust the right side width
        }
    };
    
    const handleMouseMove = (e) => {
        const xPos = e.clientX; // Current X position of the mouse
        lastX = xPos; // Track the last known X position
    
        // Adjust the dimensions continuously while dragging
        const { newWidth, minWidth, maxWidth } = calculateDimensions(xPos);
        adjustDimensions({ newWidth, minWidth, maxWidth });
    };
    
    const handleMouseUp = () => {
        // On mouse release, update the dimensions one last time based on the last tracked position
        const { newWidth, minWidth, maxWidth } = calculateDimensions(lastX);
        adjustDimensions({ newWidth, minWidth, maxWidth });
    
        // Remove event listeners after mouse release
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const [editCellDropdownHandler, setEditCellDropdownHandler] = useState({ showMenu : undefined });

    const tabComponents = {
        "all-timeline": {
            key: "timeline",
            label: "Timeline",
            icon: <TimelineIcon />,
            activity: "timeline",
            notification: undefined,
            notificationIcon: <FiberNewIcon />
        },
        "all-documents": {
            key: "documentsBtn",
            label: "Documents",
            icon: <HistoryEduIcon />,
            activity: "documents"
        },
    };

    const tertiaryInstance = session?.user?.data?.pages?.tertiary || [];
    const instancePermissions = tertiaryInstance?.viewPermissions || [];
    const approvedTabs = ["timeline", "documents"];

    const verifiedTabs = approvedTabs.filter(activity =>
        instancePermissions.includes(`all-${activity}`) && tabComponents[`all-${activity}`]
    );

    const [currentActivityView, setCurrentActivityView] = useState(() => {
        const { activity: tab, key: permission } = tabComponents[`all-${verifiedTabs[0]}`] || {};
        return { tab, permission };
    });

    // const resetShowMenu = (setDropdownState) => {
    //     setDropdownState((prev) => {
    //         if (prev?.showMenu) {
    //             return { ...prev, showMenu: false };
    //         }
    //         return prev;
    //     });
    // };

    // const resetDropdowns = () => {
    //     if (pagination?.showMenu) resetShowMenu(setPagination);
    //     if (editCellDropdownHandler?.showMenu) resetShowMenu(setEditCellDropdownHandler);
    //     if (reportSwitcher?.showMenu) resetShowMenu(setReportSwitcher);
    //     if (reportDownload?.showMenu) resetShowMenu(setReportDownload);
    // };

    function generateReportNames() {
        const portfolios = baseData?.portfolios;
        if (!portfolios) return undefined;

        const newReportVersions = {};

        // Iterate over each portfolio
        Object.values(portfolios).forEach(portfolio => {
            // Iterate over each fund within the portfolio
            Object.values(portfolio?.funds || {}).forEach(fund => {
                // Create a new key in the format "Portfolio Name - Fund Name"
                const newKey = `${portfolio?.name} - ${fund?.fundName}`;
                // Populate the new object with the formatted keys
                newReportVersions[newKey] = {
                    count : fund?.numBids,  // Updated to use the numBids specific to each fund
                    portfolioID : portfolio?.recordID,
                    relatedAccountID : fund?.relatedAccountID,
                    portfolioName : portfolio?.name,
                    portfolioDisplayName : newKey,
                    fundID : fund?.fundID,
                    tertiary : true,
                    submissionDate : portfolio?.submissionDate,
                };
            });
        });

        return newReportVersions;
    }

    const [search, setSearch] = useState({
        searching : props?.viewType !== "mobile" ? true : undefined,
        searchValue : selectedReport?.search ?? ''
    });

    const handleSearchChange = (event) => {
        const value = event?.target?.value;
        setSearch((prev) => ({
            ...prev,
            searchValue: value,
        }));
    };

    function currentActivity(activityView){
        if(currentActivityView?.tab === activityView){
            return " selected";
        }
        return '';
    }

    function switchViewBtnContainer(tabCount){
        let className = "switchViewBtnContainer g f tabCount" + tabCount;
        return className;
    }

    function compareAttribute(statusArray, attr, operation = "match") {
        return statusArray.every(status => 
            selectedReport.criteria.existing.some(
                (criterion) => criterion?.attr === attr && criterion?.criterion?.[operation] === status
            )
        );
    }

    function updateCriteria({
        formType,
        criterion,  // The operator key you want to set, e.g., "match" or "not_match"
        groupID,
        groupOperator,
        inlineOperator,
        attribute,  // A singular attribute, e.g., "shippingStatus"
        values,  // The value(s) to assign to the criterion, e.g., ["Shipped", "Initial Assessment"]
    }) {
        const updatedSelectedReport = { ...(selectedReport) };

        updatedSelectedReport.criteria.existing.forEach((item, index) => {
            const isMatch = item.attr === attribute;
        
            if (isMatch) {
                if (Array.isArray(values)) {
                    values.forEach((value) => {
                        for (let key in item.criterion) {
                            if (item.criterion[key] === value) {
                                item.formType = formType;
                                item.criterion[criterion] = value;
                                delete item.criterion[key];
                                item.groupID = groupID;
                                item.groupOperator = groupOperator;
                                item.inlineOperator = inlineOperator;
                                item.attr = attribute;
                            }
                        }
                    });
                } else {
                    item.formType = formType;
                    item.criterion[criterion] = values;
                    item.groupID = groupID;
                    item.groupOperator = groupOperator;
                    item.inlineOperator = inlineOperator;
                    item.attr = attribute;
                }
            }
        });
    
        // Reorder the items by groupID and relativeKey
        updatedSelectedReport.criteria.existing.sort((a, b) => {
            if (a.groupID !== b.groupID) {
                return a.groupID - b.groupID;
            }
            return (a.relativeKey || 0) - (b.relativeKey || 0);
        });
    
        // Update the relativeKey based on the new order within each group
        let currentGroup = -1;
        let parentIndex = null;
        updatedSelectedReport.criteria.existing.forEach((item, index) => {
            if (item.groupID !== currentGroup) {
                currentGroup = item.groupID;
                parentIndex = index; // Mark the first item in the group as the parent
                delete item.relativeKey;  // The parent should have no relativeKey
            } else {
                item.relativeKey = parentIndex;
            }
        });
    
        const items = session?.[reporting?.data?.dataPointer]?.data?.bids?.tertiary;
        const query = reporting?.functions?.buildQuery(updatedSelectedReport?.criteria?.existing);
        const filteredResults = reporting?.functions?.filterItemsWithQuery(query, items);
    
        updatedSelectedReport.query = query;
        updatedSelectedReport.list = filteredResults;
    
        return updatedSelectedReport;
    }

    function printTabs() {
        let tabBar = [];
        let tabCount = 0;

        approvedTabs.forEach(activity => {
            const permissionKey = `all-${activity}`;
            const tab = tabComponents?.[permissionKey];

            if (tab && instancePermissions.includes(permissionKey)) {
                tabBar.push(
                    <div
                        key={tab?.key}
                        className={`switchViewBtn f g cC s e${currentActivity(tab?.activity)}${!active ? " inactive" : ''}`}
                        onClick={() => { if (active) { setCurrentActivityView({ "tab": tab?.activity, "permission": permissionKey }) }}}
                    >
                        {tab.notification &&
                            <div className="notificationBubble">
                                <FiberNewIcon />
                            </div>
                        }
                        <div className="switchViewBtnOption">
                            {tab?.label}
                        </div>
                        <div className="inlineBtnIcon cC">{tab?.icon}</div>
                    </div>
                );
                tabCount++;
            }
        });

        return (
            <div className={switchViewBtnContainer(tabCount)}>
                {tabBar}
            </div>
        );
    }

    const loadTimeline = async (loaders) => {
        if (!loaders?.timeline || !session?.case?.data?.bids?.tertiary) return;
    
        updateLoadingHandler("timeline", "pending");
    
        if (baseData?.[baseData?.selectedPortfolio?.recordID]?.timelineData) {
            updateLoadingHandler("timeline", false);
            return;
        }

        let timelineData = {};

        session?.env?.functions?.buildFetchRequest("tertiary/getPortfolioActivities")
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                resData?.timeline.forEach(timelineItem => {
                    const portfolioID = timelineItem?.relatedPortfolioID;
                    const fundID = timelineItem?.fundID;

                    if (!timelineData?.[portfolioID]) {
                        timelineData[portfolioID] = {
                            openRequestCounter: 0,
                            requestCounter: 0,
                            timelineType: "default",
                            showDetails: false,
                        };
                    }

                    if (!timelineData?.[portfolioID]?.[fundID]) {
                        timelineData[portfolioID][fundID] = {
                            list: [],
                            requestCounter: 0,
                            openRequestCounter: 0
                        };
                    }

                    timelineData[portfolioID][fundID].requestCounter += 1;

                    timelineData[portfolioID][fundID].list.push(timelineItem);
                });

                const updatedPortfolios = { ...session?.bids?.data?.tertiary?.portfolios };
                Object.keys(updatedPortfolios).forEach(portfolioID => {
                    const portfolio = updatedPortfolios?.[portfolioID];

                    Object.keys(portfolio?.funds || {}).forEach(fundID => {
                        const fund = portfolio?.funds?.[fundID];

                        if (timelineData?.[portfolioID]?.[fundID]) {
                            fund.timeline = timelineData?.[portfolioID]?.[fundID];
                        }
                    });
                });

                updateLoadingHandler("timeline", false);
                return updatedPortfolios;
            }
    
        }, (error) => {
            if (error) {
                console.log(error);
                updateLoadingHandler("timeline", false);
                return false;
            }
        });
    }

    function updateReportsDropdown(){
        updateDropdown("multipleReports", {
            portfolioName : session?.bids?.data?.tertiary?.selectedPortfolio?.reportName,
            fundID : session?.bids?.data?.tertiary?.selectedPortfolio?.fundID,
            recordID : session?.bids?.data?.tertiary?.selectedPortfolio?.recordID,
            submissionDate : session?.bids?.data?.tertiary?.selectedPortfolio?.submissionDate,
            inactive : !baseData?.portfolios,
            nameList : generateReportNames() ?? ["N/A"],
            onClick : updateSelectedReport
        });
    }
    const loadShareLink = async (loaders) => {
        if (!loaders?.shareLink || !session?.case?.data?.bids?.tertiary) return;

        updateLoadingHandler("shareLink", "pending");

        const params = {
            "pullType": "portfolio",
            "link": baseData?.portfolios?.[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.shareLink
        };

        return session?.env?.functions?.buildFetchRequest("getShareLinks", params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                updateLoadingHandler("shareLink", false);
                return resData?.shareLink;
            }
            return false;
        })
        .catch((error) => {
            console.error("Error loading share link:", error);
            updateLoadingHandler("shareLink", false);
            return false;
        });
    };

    useEffect(() => {
        if(!currentPortfolio || !session?.case?.data?.bids?.tertiary) return;
        const allPortfolios = { ...session?.bids?.data?.tertiary?.portfolios };
        const localLoadingHandler = { ...loadingHandler };
        const loadersToUpdate = [
            !allPortfolios[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.timeline && "timeline",
            !allPortfolios[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.generatedShareLink && "shareLink"
        ].filter(Boolean);

        loadersToUpdate.forEach(loader => {
            localLoadingHandler[loader] = true;
        });
    
        updateLoadingHandler(loadersToUpdate, true);

        const loadData = async (loaders) => {
            const affectedLoaders = [];
            try {
                const promises = [];

                if (!baseData?.portfolios?.[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.timeline) {
                    promises.push(loadTimeline(loaders).then(data => {
                        allPortfolios[currentPortfolio?.recordID].funds[currentPortfolio?.fundID].timeline = data;
                        affectedLoaders.push("timeline");
                    }));
                } else {
                    updateLoadingHandler("timeline", false);
                }

                if (!baseData?.portfolios?.[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.generatedShareLink) {
                    promises.push(loadShareLink(loaders).then(data => {
                        allPortfolios[currentPortfolio?.recordID].funds[currentPortfolio?.fundID].generatedShareLink = data;
                        affectedLoaders.push("shareLink");
                    }));
                } else {
                    updateLoadingHandler("shareLink", false);
                }

                await Promise.all(promises);
                if (JSON.stringify(allPortfolios) !== JSON.stringify(session?.bids?.data?.tertiary?.portfolios)) {
                    session?.set("bids", `tertiary.portfolios`, allPortfolios);
                    updateLoadingHandler(affectedLoaders, false);
                }
            } catch (error) {
                console.error(321, "Error loading data:", error, baseData);
            }
        };

        loadData(localLoadingHandler);
        setPreferredFormat({
            dataTape: {
                friendlyTerm: "Data Tape",
                format: "dataTape",
                loadingStatus: false,  // Default loading status
                onClick: () => downloadDataTape(),
            },
            report: {
                friendlyTerm: "Report",
                format: "report",
                onClick: () => downloadReport(),
            }
        });
    }, [currentPortfolio?.recordID, currentPortfolio?.fundID]);

    useEffect(() => {
        setCurrentPortfolio(session?.bids?.data?.tertiary?.selectedPortfolio);
        updateReportsDropdown();
    }, [session?.bids?.data?.tertiary?.selectedPortfolio]);

    useEffect(() => {
        if(!baseData?.portfolios) return;

        if(dropdowns?.multipleReports?.inactive || !dropdowns?.multipleReports?.nameList){
            updateReportsDropdown();
        }
    }, [baseData?.portfolios, selectedReport]);

    useEffect(() => {
        // if (session?.env?.isTyping || !typing) return;
        if (session?.env?.isTyping) return;
        session?.set('bids', 'tertiary.selectedReport.search', search?.searchValue);

        // setTyping(false);
    }, [session?.env?.isTyping, search.searchValue]);

    return (
        <div
            className="tertiaryPage g bR oH"
            onClick={resetDropdowns}
        >
            {!baseData?.portfolios || !session?.bids?.data?.tertiary ?
                <LoadingScreen
                    page="tertiary"
                />
            :
            currentPath?.recordID ? 
                <SingleView
                    session={session}
                    branch="bids"
                    stem="tertiary"
                    recordID={currentPath?.recordID}
                />
            :
                <div className="tertiaryHub g oH" style={{ gridTemplateColumns: baseData?.layout?.dimensions }}>
                    <div className="reportBuilder tertiary remote g f dG s b">
                        <ReportTopbar
                            session={props?.session}
                            branch={props?.branch}
                            stem={props?.stem}
                            reportingView={reportingView}
                            handleSearchChange={handleSearchChange}
                            search={{data : search, set : setSearch}}
                            selectedReport={selectedReport}
                            remote={true}
                            baseModule={"bids"}
                            baseData={baseData}
                            reportType={"inherited"}
                            // multipleReports={multipleReports}
                            remotePath={"tertiary."}
                            preferredFormat={preferredFormat}
                            dropdowns={{data : dropdowns, set : updateDropdown}}
                        />
                        <TertiaryReportToggles
                            session={session}
                            baseModule={"bids"}
                            baseData={baseData}
                            compareAttribute={compareAttribute}
                            updateCriteria={updateCriteria}
                            active={active}
                            dropdowns={{data : dropdowns, set : updateDropdown}}
                        />
                        {active ?
                            <ReportSheet
                                session={session}
                                remoteReport={selectedReport ?? undefined}
                                remotePath={`tertiary.`}
                                reportType={"inherited"}
                                // pagination={{data : pagination, set : setPagination}}
                                dropdowns={{data : dropdowns, set : updateDropdown}}
                                baseModule={"bids"}
                                baseData={baseData}
                                editCellDropdownHandler={{
                                    data: editCellDropdownHandler,
                                    set: (newValue) => setEditCellDropdownHandler(prev => ({ ...prev, showMenu: newValue }))
                                }}
                            />
                        :
                            <div className="f cC bR loading">
                                <div className="g dG">
                                    <RecentActorsTwoToneIcon/>
                                    <div className="gC2">
                                        No portfolio selected
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="alignmentContainer pR g fR fC">
                        <div
                            // className={`columnDrag g cC${dragging?.current ? " dragging" : ''}`}
                            className={`columnDrag g cC fR fC`}
                            onMouseDown={handleMouseDown}
                            // onMouseDown={(e) => setupDragHandlers(column, index - allColumns("count")).handleMouseDown(e)}
                            // onDoubleClick={columnDoubleClickHandler}
                        >
                            <div className="bar">
                            </div>
                        </div>
                    </div>
                    <div className="activity f g cC fC b e dG">
                        <div className="topBar g fR f">
                            <div className="tabs g f">
                                {printTabs()}
                            </div>
                            <div className="moreBtn cC f p">
                                <MoreHorizIcon/>
                            </div>
                        </div>
                        <CurrentActivity
                            session={session}
                            activityView={{ data: currentActivityView, set : setCurrentActivityView }}
                            loadingHandler={loadingHandler}
                            currentCase={{data : currentPortfolio}}
                            active={active}
                            verifiedTabs={verifiedTabs}
                            branch="bids"
                            stem="tertiary"
                            portfolioID={currentPortfolio?.recordID}
                            shareLink={baseData?.portfolios?.[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.generatedShareLink}
                            instance="portfolio"
                            timelineData={baseData?.portfolios?.[currentPortfolio?.recordID]?.funds?.[currentPortfolio?.fundID]?.timeline}
                            requirementPrompt="Please select a portfolio to get started."
                            approvedTabs={["timeline", "documents"]}
                        />
                    </div>
                </div>
            }

        </div>
    )
}

export default Tertiary;