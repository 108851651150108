import React from 'react';
import { useState, useEffect, useRef } from 'react';

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import GavelIcon from '@mui/icons-material/Gavel';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import AssistantTwoToneIcon from '@mui/icons-material/AssistantTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import TvTwoToneIcon from '@mui/icons-material/TvTwoTone';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';
import PhoneIphoneTwoToneIcon from '@mui/icons-material/PhoneIphoneTwoTone';
import LoyaltyTwoToneIcon from '@mui/icons-material/LoyaltyTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone';
import PhoneForwardedTwoToneIcon from '@mui/icons-material/PhoneForwardedTwoTone';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';

import StringInput from '../Components/StringInput.js';

function DetailsTab(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const shareLink = props?.shareLink;
    const currentCase = props?.currentCase?.data;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

    const topShadowRef = useRef(null);
    const bottomShadowRef = useRef(null);
    const scrollContainerRef = useRef(null);

    const [isScrollable, setIsScrollable] = useState(false);
    const [isTopVisible, setIsTopVisible] = useState(false);
    const [isBottomVisible, setIsBottomVisible] = useState(false);

    function leadSourceTag(returnData) {
        if(!currentCase?.leadSource) return undefined;
        const leadSourceMap = {
            "Abacus Pays": { className: "abacusPays", color: "#1b5e20", backgroundColor: "#c8e6c9", icon: <StarTwoToneIcon /> },
            "Television": { className: "abacusPays", color: "#1b5e20", backgroundColor: "#c8e6c9", icon: <TvTwoToneIcon /> },
            "Digital": { className: "abacusPays", color: "#1b5e20", backgroundColor: "#c8e6c9", icon: <DevicesTwoToneIcon /> },
            "Phone": { className: "abacusPays", color: "#1b5e20", backgroundColor: "#c8e6c9", icon: <PhoneIphoneTwoToneIcon /> },
            "Marketplace": { className: "direct", color: "#4a148c", backgroundColor: "#e1bee7", icon: <LoyaltyTwoToneIcon /> },
            "Direct": { className: "direct", color: "#4a148c", backgroundColor: "#e1bee7", icon: <LoyaltyTwoToneIcon /> },
            "Serviced Policy": { className: "serviced", color: "#0d47a1", backgroundColor: "#e3f2fd", icon: <LibraryBooksTwoToneIcon /> },
            "Agent": { className: "agent", color: "#f57f17", backgroundColor: "#FFEFA9", icon: <SupportAgentTwoToneIcon /> },
            "Insured": { className: "clientDirect", color: "#880e4f", backgroundColor: "#fce4ec", icon: <GroupTwoToneIcon /> },
            "Broker": { className: "broker", color: "#1a237e", backgroundColor: "#e8eaf6", icon: <SavingsTwoToneIcon /> },
            "Client Direct": { className: "clientDirect", color: "#880e4f", backgroundColor: "#fce4ec", icon: <PhoneForwardedTwoToneIcon /> },
        };
    
        const defaultConfig = { className: "other", color: "#e65100", backgroundColor: "#fff3e0", icon: <ShareTwoToneIcon /> };
        const config = leadSourceMap[currentCase?.leadSource] || defaultConfig;
    
        if (returnData) {
            return config;
        }
    
        return (
            <div
                key="leadSourceTag"
                className={`leadSourceTag cL g bR dG s e oH f ${config.className}`}
                title={`Lead Source: ${currentCase?.leadSource}`}
            >
                {config.icon}
                <div className="gCW f bold">{currentCase?.leadSource}</div>
            </div>
        );
    }

    const formHandler = () => {
        const formTemplates = {
            policies : {
                origination :[
                    {
                        header: { value: "Primary Information", icon: <PersonOutlineTwoToneIcon /> },
                        inputs: createInputGroups([
                            [["primaryFirstName", true], ["primaryLastName", true]],
                            [["primaryBirthDate", true], ["age", true], ["primaryGender", true]],
                        ])
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFirstName", true], ["secondaryLastName", true]],
                            [["secondaryBirthDate", true], ["secondaryAge", true], ["secondaryGender", true]],
                        ]),
                    }, {
                        header: {
                            value : `Policy Information`,
                            icon : <FeedTwoToneIcon />,
                            subHeader : {
                                header : [{
                                        value: `${currentCase?.policyNumber ?? ''}`,
                                        title: `Policy Number: ${currentCase?.policyNumber}`
                                    }],
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["insuranceCompany", true], ["issueDate", true]],
                            [["policyStatus", true], ["deathBenefit", true]],
                            [["ownerSitusState", true], ["ownerType", true]],
                        ])
                    }, {
                        header: {
                            value: "Case Assistance",
                            icon: <AssistantTwoToneIcon />,
                            employeeOnly : true,
                        },
                        inputs: createInputGroups([
                            [["accountManager", true], ["caseProcessor", true], ["caseOwner", true]],
                        ])
                    }
                ],
                servicing :[
                    {
                        header: { value: "Primary Information", icon: <PersonOutlineTwoToneIcon /> },
                        inputs: createInputGroups([
                            [["primaryFirstName", true], ["primaryLastName", true]],
                            [["primaryBirthDate", true], ["age", true], ["primaryGender", true]],
                            [["primaryPhoneNumber", true], ["primaryEmail", true]],
                        ])
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFirstName", true], ["secondaryLastName", true]],
                            [["secondaryBirthDate", true], ["secondaryAge", true], ["secondaryGender", true]],
                        ]),
                    }, {
                        header: {
                            value : `Policy Information`,
                            icon : <FeedTwoToneIcon />,
                            subHeader : {
                                header : [{
                                    value: `${currentCase?.policyNumber ?? ''}`,
                                    title: `Policy Number: ${currentCase?.policyNumber}`
                                }],
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["insuranceCompany", true], ["issueDate", true]],
                            [["policyStatus", true], ["policyType", true]],
                            [["deathBenefit", true], ["deathBenefitType", true]],
                            [["bookValue", true], [false, true]]
                        ])
                    }
                ]
            },
            bids : {
                bids : [
                    {
                        header : {
                            value: "Primary Information",
                            icon: <PersonOutlineTwoToneIcon />
                        },
                        inputs : createInputGroups([
                            [["primaryFullName", true], ["primaryGender", true], ["primaryBirthDate", true]]]),
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFullName", true], ["secondaryGender", true], ["secondaryBirthDate", true]]
                        ]),
                    }, {
                        header: {
                            value: `Policy Information`,
                            icon: <FeedTwoToneIcon />,
                            subHeader: {
                                header: [
                                    {
                                        value: `${currentCase?.policyNumber ?? ''}`,
                                        title: `Policy Number: ${currentCase?.policyNumber}`
                                    },
                                    {
                                        value: leadSourceTag(),
                                        title: `Lead Source: ${currentCase?.leadSource}`
                                    }
                                ],
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["shippingStatus", true], ["policyType", true], ["deathBenefit", true]],
                            [["insuranceCompany", true], ["issueDate", true], ["clarinetRecordID", true]],
                        ])
                    }, {
                        header: {
                            value: "Case Assistance",
                            icon: <AssistantTwoToneIcon />,
                            employeeOnly : true,
                        },
                        inputs: createInputGroups([
                            [["accountManager", true], ["caseProcessor", true], ["caseOwner", true]],
                        ])
                    }
                ],
                tertiary : [
                    {
                        header : {
                            value: "Primary Information",
                            icon: <PersonOutlineTwoToneIcon />
                        },
                        inputs : createInputGroups([
                            [["primaryFullName", true], ["primaryGender", true]],
                            [["primaryBirthDate", true], ["age", true]]])
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFullName", true], ["secondaryGender", true]],
                            [["secondaryBirthDate", true], ["secondaryAge", true]]])
                    }, {
                        header: {
                            value : `Policy Information`,
                            icon : <FeedTwoToneIcon />,
                            subHeader : {
                                header : [{
                                    value: `${currentCase?.policyNumber ?? ''}`,
                                    title: `Policy Number: ${currentCase?.policyNumber}`
                                }],
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["shippingStatus", true], ["policyType", true], ["faceValue", true]],
                            [["insuranceCompany", true], ["issueDate", true]],
                            [["clarinetRecordID", true], ["portfolioName", true]],
                            [["securityInterText", true], ["policyOwnedBy", true]]
                        ])
                    }
                ],
            },
            leads : {
                leads : [
                    {
                        header : {
                            value: "Lead Information",
                            icon: <FeedTwoToneIcon />,
                            subHeader : {
                                header : [{
                                    value: `${currentCase?.policyNumber ?? ''}`,
                                    title: `Policy Number: ${currentCase?.policyNumber}`
                                }],
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs : createInputGroups([
                            [["primaryFirstName", true], ["primaryLastName", true], ["age", true]],
                            [["primaryGender", true], ["deathBenefit", true], ["healthStatus", true]],
                            [["policyType", true], ["leadStatus", true], ["unqualifiedReason", true]]
                        ]),
                    }, {
                        header : {
                            value: "Contact Information",
                            icon: <PersonOutlineTwoToneIcon />,
                        },
                        inputs : createInputGroups([
                            [["primaryPhoneNumber", true], ["primaryEmail", true], ["timezone", true]]]),
                    }, {
                        header : {
                            value: "Calculator Results",
                            icon: <CalculateTwoToneIcon />
                        },
                        inputs: createInputGroups([
                            [["calculatorEstimatedStandard", true], ["calculatorEstimatedSlightlyImpaired", true]],
                            [["calculatorEstimatedHeavilyImpaired", true], ["calculatorEstimatedTerminal", true]],
                        ])
                    }
                ],
            }
        }
    
        return formTemplates?.[props?.branch]?.[props?.stem] ?? [];
    };

    const createInputGroups = (inputs) => {
        return inputs.map(group => {
            if (!Array.isArray(group)) {
                return {};
            }
    
            return group.reduce((groupObject, item) => {
                if (Array.isArray(item) && item.length === 2) {
                    const [key, readOnly] = item;
                    if (key === false) {
                        // Add a placeholder entry when key is false
                        return {
                            ...groupObject,
                            skip: { // Use a special key like "placeholder" or a similar name
                                value: '',
                                readOnly: true
                            }
                        };
                    } else if (key !== null && key !== undefined) {
                        return {
                            ...groupObject,
                            [key]: {
                                value: currentCase?.[key] ?? '',
                                readOnly
                            }
                        };
                    }
                } else if (typeof item === 'string') {
                    return {
                        ...groupObject,
                        [item]: {
                            value: currentCase?.[item] ?? '',
                            readOnly: true
                        }
                    };
                }
                return groupObject;
            }, {});
        });
    };
    
    const getShippingStatusStyle = (value) => {
        switch (value) {
            case 'Shipped':
            case 'Initial Assessment':
                return {
                    color : "#1b5e20",
                    backgroundColor : "#e8f5e9",
                    border : "solid 2px #1b5e20",
                    icon : <InboxIcon/>
                };
            case 'Under Review':
            case 'Phase II':
            case 'Requested Info':
            case 'Request LEs / Datatape':
                return {
                    color : "#01579b",
                    backgroundColor : "#e3f2fd",
                    border : "solid 2px #01579b",
                    // icon : <FlagCircleIcon/>
                };
            case 'Bidding':
                return {
                    color : "#6a1b9a",
                    backgroundColor : "#f3e5f5",
                    border : "solid 2px #6a1b9a",
                    icon : <CampaignIcon/>
                };
            case 'Closed Won':
                return {
                    color : "#004d40",
                    backgroundColor : "#e0f2f1",
                    border : "solid 2px #004d40",
                    icon : <CelebrationIcon/>
                };
            case 'Closing':
                return {
                    color : "#ff9800",
                    backgroundColor : "#fff3e0",
                    border : "solid 2px #ff9800",
                    icon : <GavelIcon/>
                };
            case 'Declined':
            case 'No Bid':
            case 'Does Not Fit Parameters':
            case 'In Conflict':
            case 'Lost with bid':
                return {
                    color : "#ef5350",
                    backgroundColor : "#ffebee",
                    border : "solid 2px #ef5350",
                    icon : <DoDisturbOnIcon/>
                };
            default:
                return undefined; // Return an empty object or a default style if no match is found
        }
    };

    function currency(value, prompt){
        if(!value) return prompt ?? "-"

        return session?.env?.functions?.convertIntToCurrency(value);
    }

    function printDetailsInteraction(){
        if(props?.stem === "origination"){
            return (
                <div
                    key={"originatorStatement"}
                    className={`summaryStatement g cC dG${!isScrollable ? " s" : ''}`}
                >
                    <div className={`section one g fC cC dP alt f`}>
                        <div className="icon g cC f fR total bold">
                            <div className="f cC oH">
                                <div className="gCW">
                                    {session?.env?.functions?.convertIntToCurrency(currentCase?.bids?.[0]?.bidAmount)}
                                </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Highest Bid
                        </div>
                    </div>
                    <div className={`section two g fC cC dP f`}>
                        <div className="icon g cC f fR total bold">
                            <div className="f cC">
                                {Object.keys(currentCase?.bids || {}).length}
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Bid Count
                        </div>
                    </div>
                    <div className={`section three split g f fC cC dP`}>
                        <div className="icon g cC f dG">
                            <div>
                                <div className="f cC">
                                    Qualified
                                </div>
                                <div className="f cC">
                                    {currentCase?.numQualifiedFunds ?? 0}
                                </div>
                            </div>
                            <div>
                                <div className="f cC">
                                    Declined
                                </div>
                                <div className="f cC">
                                    {currentCase?.numUnqualifiedFunds ?? 0}
                                </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="data f cC">
                            Funds Shown
                        </div>
                    </div>
                </div>
            )
        }
        if(props?.stem === "servicing" || props?.stem === "bids"){
            return (
                <div className={`detailsInteraction ${props?.stem} f g s dG`}>
                    {props?.stem === "bids" &&
                        <>
                            <div className={`marketPrice f g cC bR dP dG bold oH pR${currentCase?.marketPrice ? " active" : " inactive"}`}>
                                <div className="f bC fR">
                                    Market Price
                                </div>
                                <div className="data f tC fR">
                                    {currency(currentCase?.marketPrice, "Pending")}
                                </div>
                            </div>
                            <div className={`currentOffer f g bR oH pR${currentCase?.grossBid ? " active" : " inactive"}`}>
                                {currentCase?.grossBid ?
                                    <>
                                        <div className="date f fR g dG dP">
                                            <div className="f bC">
                                                Your offer on
                                            </div>
                                            <div className="f tC bold">
                                                {session?.env?.functions?.reformatDate(currentCase?.bidDate) ?? "N/A"}
                                            </div>
                                        </div>
                                        <div className="f cC bold data">
                                            {currency(currentCase?.grossBid)}
                                        </div>
                                    </>
                                :
                                    <div className="f cC bold">
                                        No submitted offer
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {props?.stem === "servicing" &&
                        <>
                            <div className="nextPremium g f bR dP dG cC fC">
                                <div className="header g bold cC dG f">
                                    <div className="f cL dG g">
                                        <PaymentsTwoToneIcon/>
                                        <div className="f cL gC2">
                                            Next Premium
                                        </div>
                                    </div>
                                    <div className="value bold">
                                        {session?.env?.functions?.reformatDate(currentCase?.premiums?.nextPremium?.date) ?? "-"}
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data g dG f">
                                    <div className="dataPoint g dG bold cC">
                                        {currency(currentCase?.premiums?.nextPremium?.projectedPremium)}
                                    </div>
                                </div>
                            </div>
                            <div className="previousVOC g f bR dP dG cC fC">
                                <div className="header g bold cC dG f">
                                    <div className="f cL dG g">
                                        <GppGoodTwoToneIcon/>
                                        <div className="f cL gC2">
                                            Last VOC
                                        </div>
                                    </div>
                                    <div className="value bold">
                                        {session?.env?.functions?.reformatDate(currentCase?.vocData?.lastVOC?.completedDate) ?? "-"}
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data g dG f">
                                    {/* <div className="dataPoint g dG">
                                        <div className="prompt">
                                            Date
                                        </div>
                                        <div className="value bold">
                                            {session?.env?.functions?.reformatDate(currentCase?.vocData?.lastVOC?.completedDate) ?? "-"}
                                        </div>
                                    </div> */}
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Cost of Insurance">
                                            COI
                                        </div>
                                        <div className="value bold">
                                            {currency(currentCase?.vocData?.lastVOC?.currentCOI)}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Account Value">
                                            AV
                                        </div>
                                        <div className="value bold">
                                            {currency(currentCase?.vocData?.lastVOC?.accountValue)}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Cash Surrender Value">
                                            CSV
                                        </div>
                                        <div className="value bold">
                                            {currency(currentCase?.vocData?.lastVOC?.cashValue)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            )
        }
    }

    function printForm() {
        const formData = formHandler(currentCase);
        if (!formData) return null;

        return (
            <>
                {formData?.map((section, sectionIndex) => {
                    // Check if the section is conditional and has no values to display
                    if (section?.header?.conditional) {
                        const hasValue = section.inputs.some(inputGroup =>
                            Object.entries(inputGroup).some(([key, attr]) => attr?.value)
                        );
                        if (!hasValue) return null;
                    }

                    if (section?.header?.employeeOnly && !session?.user?.data?.isAbacusEmployee) return null;

                    return (
                        <div className="section g dG dP" key={sectionIndex}>
                            <div className="header f g cL dG fR">
                                <div className="f cC">
                                    {section?.header?.icon}
                                </div>
                                <span className="bold gCW f" title={section?.header?.value}>
                                    {section?.header?.value}
                                </span>
                                {section?.header?.subHeader?.header?.filter(Boolean)?.map((subHeaderItem, index) =>
                                    React.isValidElement(subHeaderItem?.value)
                                        ? subHeaderItem?.value
                                        : <span className="italics" title={subHeaderItem?.title} key={index}> {subHeaderItem?.value}</span>
                                )}
                                {section?.header?.subHeader?.dataPoint && (
                                    <div
                                        className={`dataPoint bR bold lH g fR f cC${getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon ? " dG" : ''}`}
                                        style={{
                                            backgroundColor: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.backgroundColor,
                                            color: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.color,
                                            border: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.border,
                                        }}
                                    >
                                        {getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon}
                                        <span className={`f cC gC2${getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon ? " gC2" : ''}`}>
                                            {`${section?.header?.subHeader?.dataPoint}`}
                                        </span>
                                    </div>
                                )}
                                {section?.header?.subHeader && (
                                    section?.header?.subHeader?.link?.href &&
                                        (!section?.header?.subHeader?.link?.employeeOnly || session?.user?.data?.isAbacusEmployee) && (
                                            <div className="f cR gC5">
                                                <span className="link cR dG p">
                                                    <PublicTwoToneIcon/>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={section?.header?.subHeader?.link?.href}
                                                        className="gCW f"
                                                    >
                                                        {section?.header?.subHeader?.link?.prompt}
                                                    </a>
                                                </span>
                                            </div>
                                        )
                                )}
                            </div>
                            {section.inputs.map((inputGroup, groupIndex) => (
                                <div
                                    key={groupIndex}
                                    className="row g cC dG"
                                    style={{ gridTemplateColumns: `repeat(${Object.keys(inputGroup)?.length}, 1fr)` }}
                                >
                                    {Object.entries(inputGroup).filter(([key]) => key !== "skip").map(([key, attr]) => (
                                        <StringInput
                                            session={session}
                                            key={key}
                                            placeholder={attributeData?.[key]?.friendlyTerm}
                                            value={attr?.value ?? ''}
                                            textData={attributeData?.[key]}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </>
        );
    }

    const handleScrollAndResize = () => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollTop = container.scrollTop;
            const scrollHeight = container.scrollHeight;
            const clientHeight = container.clientHeight;
    
            // Update shadow visibility based on scroll position
            setIsTopVisible(scrollTop > 0);
            setIsBottomVisible(scrollTop + clientHeight < scrollHeight);
    
            // Update if scrolling is possible
            setIsScrollable(scrollHeight > clientHeight);
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
    
        if (container) {
            // Initial checks for both scroll and shadows
            handleScrollAndResize();
    
            // Attach scroll event listener
            container.addEventListener('scroll', handleScrollAndResize);
    
            // Set up ResizeObserver to trigger on container resize
            const resizeObserver = new ResizeObserver(handleScrollAndResize);
            resizeObserver.observe(container);
    
            // Cleanup on unmount
            return () => {
                container.removeEventListener('scroll', handleScrollAndResize);
                resizeObserver.disconnect();
            };
        }
    }, []);

    return (
        <div
            key="details"
            ref={scrollContainerRef}
            className={`detailsTab f g oA${!currentCase ? " s inactive" : ''}`}
        >
            {isTopVisible && isScrollable && <div ref={topShadowRef} className={`shadow top`} />}
            {!currentCase ?
                <div className="loading f cC g bR">
                    <CircularProgress color="inherit"/>
                </div>
            :
                <>
                    {printDetailsInteraction()}
                    <div className="form">
                        {printForm()}
                    </div>
                </>
            }
            {isBottomVisible && isScrollable && <div ref={bottomShadowRef} className={`shadow bottom`} />}
        </div>
    );
}

export default DetailsTab;